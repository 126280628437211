import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as Leaflet from 'leaflet';
import { fromEvent, of, race, Subscription, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LeafletIcons } from '../../model/leaflet-color-markers';
import { LegacyContentService } from '../../services/legacy-content.service';
import { ContentApiService } from '@alfresco/aca-shared';
import { extractNodeId } from '../../api/utils/node';
import { EpmIssueModel } from '../../api/models/legacy/epm-issue-issue.model';
import { Router } from '@angular/router';
import { PlanFilerModel } from './plan-filter/plan-filer.model';
import { TranslationService } from '@alfresco/adf-core';
import { PlanService } from '../form/services/plan.service';
import { PlanFilterComponent } from './plan-filter/plan-filter.component';

Leaflet.Icon.Default.imagePath = 'assets/';
@Component({
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlanComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('mapContainer')
  mapContainer: ElementRef;

  @ViewChild('filtersRef')
  filtersRef: PlanFilterComponent;

  /** Leaflet map object (initially empty) */
  leafLetMap!: Leaflet.Map;
  leafletOptions: Leaflet.MapOptions = {
    crs: Leaflet.CRS.Simple /** Simple coordinate system for the image */,
    center: [0, 0],
    zoom: 0,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
    minZoom: -5,
    maxZoom: 4,
    doubleClickZoom: false
  };
  leafletLayers: Leaflet.Layer[] = [];

  private planFilters: PlanFilerModel = new PlanFilerModel();

  private leafletMarkers: Leaflet.Marker[] = [];
  private leafletLegend: Leaflet.Control;

  private planIssues: EpmIssueModel[] = [];
  private siteInputChangeSub: Subscription;
  private planInputChangeSub: Subscription;
  private planIssuesSub: Subscription;
  private markerCatInputSub: Subscription;
  private imgMetaSub: Subscription;
  private filtersChangeSub: Subscription;

  constructor(
    private legacyApi: LegacyContentService,
    private contentApi: ContentApiService,
    private router: Router,
    private translation: TranslationService,
    private planService: PlanService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const plan = this.planService.selectedPlan$.getValue();
    if (plan) {
      this.onFiltersChange(plan);
      this.filtersRef.isExpanded = false;
    }
  }

  onFiltersChange(filtersFormData: PlanFilerModel) {
    this.planFilters = filtersFormData;
    const planRef = filtersFormData.planFormGroup.plan;
    if (planRef) {
      this.planService.setSelectedPlan(filtersFormData);
      this._renderNewPlan(filtersFormData);
    }
  }

  onMapReady(map: Leaflet.Map) {
    this.leafLetMap = map;

    // calculate the edges of the image, in coordinate space
    const mapImgHeight = 691;
    const mapImgWidth = 541;
    const bounds = this._createMapBounds(mapImgHeight, mapImgWidth);

    this.leafLetMap.setMaxBounds(bounds);

    // this is a crucial line, without it, the styles are broken
    setTimeout(() => this.leafLetMap.invalidateSize(true), 100);

    this._createMapLegend();
  }

  private _createMapBounds(imgHeight: number, imgWidth: number): Leaflet.LatLngBounds {
    const southWest = new Leaflet.LatLng(-imgHeight, 0);
    const northEast = new Leaflet.LatLng(0, imgWidth);
    return new Leaflet.LatLngBounds(southWest, northEast);
  }

  onMapClick(event: Leaflet.LeafletMouseEvent) {
    console.log('Map clicked: ', event.latlng.lat, event.latlng.lng);
  }

  onAddNewIssueClick() {
    // redirect to create new workflow
    this.router.navigate(['epm/process/new']);
  }

  ngOnDestroy() {
    this.siteInputChangeSub?.unsubscribe();
    this.planInputChangeSub?.unsubscribe();
    this.planIssuesSub?.unsubscribe();
    this.markerCatInputSub?.unsubscribe();
    this.imgMetaSub?.unsubscribe();
    this.filtersChangeSub?.unsubscribe();
  }

  private _renderNewPlan(filtersFormData: PlanFilerModel) {
    // clear old plan
    this.leafletLayers = [];

    // clear old markers
    this.leafletMarkers.forEach((marker) => this.leafLetMap.removeLayer(marker));

    this.filtersChangeSub = this.legacyApi
      .getIssuePlanIssues(filtersFormData.toIssueReportRequestModel())
      .subscribe((planIssues: EpmIssueModel[]) => {
        this.planIssues = planIssues;
        this._renderPlanOnMap();
        this._renderPlanIssues();
        this._createMapLegend();
      });
  }

  private _renderPlanOnMap() {
    const planNodeRef: string = this.planFilters.planFormGroup?.plan;
    const nodeUrl = this.contentApi.getContentUrl(extractNodeId(planNodeRef));
    this.imgMetaSub = this._getImageMeta(nodeUrl).subscribe((img) => {
      const w = img['width'];
      const h = img['height'];
      const bounds = this._createMapBounds(h, w);
      this.leafLetMap.setMaxBounds(bounds);

      setTimeout(() => {
        // this is needed to get correct mapContainer size
        const boundsZoom = this._calcZoomLevel(w, h);
        this.leafLetMap.setZoom(boundsZoom);

        this.leafletLayers = [Leaflet.imageOverlay(nodeUrl, bounds, {})];
        // this is a crucial line, without it, the styles are broken
        setTimeout(() => this.leafLetMap.invalidateSize(true), 100);
      }, 100);
    });
  }

  private _calcZoomLevel(imgWidth: number, imgHeight: number): number {
    const containerWidth = this.mapContainer.nativeElement.offsetWidth;
    const containerHeight = this.mapContainer.nativeElement.offsetHeight;

    // we assume 1px is equal to 1 map point

    const scaleW = containerWidth / imgWidth;
    const scaleH = containerHeight / imgHeight;
    const scale = Math.min(scaleW, scaleH);
    const zoom = this.leafLetMap.options.crs.zoom(scale);

    return zoom;
  }

  private _getImageMeta(url) {
    return of(url).pipe(
      mergeMap((path) => {
        const img = new Image();
        let load = fromEvent(img, 'load').pipe(map((_) => img));
        let error = fromEvent(img, 'error').pipe(mergeMap((err) => throwError(() => err)));
        img.src = path;
        return race(load, error);
      })
    );
  }

  private _renderPlanIssues() {
    // remove old markers
    this.leafletMarkers.forEach((marker) => this.leafLetMap.removeLayer(marker));

    // render new markers
    this.planIssues.forEach((issue) => {
      const location: Leaflet.LatLngExpression = new Leaflet.LatLng(issue.locationLat, issue.locationLng);
      const icon = this._getMarkerIcon(issue);
      const marker = Leaflet.marker(location, { draggable: false, icon: icon });
      this.leafletMarkers.push(marker);
      marker.addTo(this.leafLetMap).bindPopup(`<b><a href="/adf/#/epm/process/${issue.processId}">${issue.name}</a>`);
    });
  }

  /**
   * Get marker icon color based on the marker category status
   * @param issue
   * @private
   */
  private _getMarkerIcon(issue: EpmIssueModel): Leaflet.Icon {
    const markerCategory = this.planFilters.issuesFormGroup?.markerColorCategory;
    switch (markerCategory) {
      case 'status':
        if (issue.completionDate) {
          // it means the issue workflow is completed
          return LeafletIcons[1].icon;
        }
        return LeafletIcons[0].icon;
      case 'priority':
        if (issue.priority == 3) {
          // green for low priority
          return LeafletIcons[3].icon;
        }
        if (issue.priority == 2) {
          // yellow for medium priority
          return LeafletIcons[1].icon;
        }
        if (issue.priority == 1) {
          // red for high priority
          return LeafletIcons[2].icon;
        }
        return LeafletIcons[3].icon;
      case 'department':
        if (!issue.departments || issue.departments.length === 0 || issue.departments.length > 1) {
          // when there are no departments or more than one, categorize to "Other"
          return LeafletIcons[8].icon;
        }
        if (issue.departments.includes('architecture')) {
          return LeafletIcons[0].icon;
        }
        if (issue.departments.includes('construction')) {
          return LeafletIcons[1].icon;
        }
        if (issue.departments.includes('lowCurrent')) {
          return LeafletIcons[2].icon;
        }
        if (issue.departments.includes('sanitary')) {
          return LeafletIcons[3].icon;
        }
        if (issue.departments.includes('road')) {
          return LeafletIcons[4].icon;
        }
        if (issue.departments.includes('railway')) {
          return LeafletIcons[5].icon;
        }
        if (issue.departments.includes('bridge')) {
          return LeafletIcons[6].icon;
        }
        if (issue.departments.includes('hydraulic')) {
          return LeafletIcons[7].icon;
        }
        if (issue.departments.includes('technological')) {
          return LeafletIcons[8].icon;
        }
        if (issue.departments.includes('landscape')) {
          return LeafletIcons[8].icon;
        }
        return LeafletIcons[8].icon;
      default:
        return LeafletIcons[0].icon;
    }
  }

  /**
   * Create leaflet map legend to know what means the marker color
   * @private
   */
  private _createMapLegend() {
    const data: { color: string; name: string }[] = [];
    const markerCategory = this.planFilters.issuesFormGroup?.markerColorCategory;
    if (!markerCategory) {
      return;
    }
    switch (markerCategory) {
      case 'status':
        data.push(
          { color: LeafletIcons[0].color, name: this.translation.instant('EPM.BROWSE.PLANS.FILTER.STATUS.ACTIVE') },
          { color: LeafletIcons[1].color, name: this.translation.instant('EPM.BROWSE.PLANS.FILTER.STATUS.COMPLETED') }
        );
        break;
      case 'priority':
        data.push(
          { color: LeafletIcons[2].color, name: this.translation.instant('EPM.FORM.PRIORITY.1') },
          { color: LeafletIcons[1].color, name: this.translation.instant('EPM.FORM.PRIORITY.2') },
          { color: LeafletIcons[3].color, name: this.translation.instant('EPM.FORM.PRIORITY.3') }
        );
        break;
      case 'department':
        data.push(
          { color: LeafletIcons[0].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.ARCHITECTURE') },
          { color: LeafletIcons[1].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.CONSTRUCTION') },
          { color: LeafletIcons[2].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.LOWCURRENT') },
          { color: LeafletIcons[3].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.SANITARY') },
          { color: LeafletIcons[4].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.ROAD') },
          { color: LeafletIcons[5].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.RAILWAY') },
          { color: LeafletIcons[6].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.BRIDGE') },
          { color: LeafletIcons[7].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.HYDRAULIC') },
          { color: LeafletIcons[8].color, name: this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.OTHERS') }
        );
        break;
    }

    // Sample data:
    // const data: {color: string, name: string}[] = [
    //   {color: "#477AC2", name:"Aktywne"},
    //   {color: "#448D40", name:"Zakończone"},
    //   {color: "#E6E696", name:"Zawieszone"},
    //   {color: "#E8E6E0", name:"Archiwalne"},
    // ]
    this._createMapLegendFromData(data);
  }

  private _createMapLegendFromData(data: { color: string; name: string }[]) {
    // remove old legend
    if (this.leafletLegend) {
      this.leafLetMap.removeControl(this.leafletLegend);
    }

    // create new legend
    const legendDiv = new Leaflet.Control({ position: 'bottomleft' });
    legendDiv.onAdd = (_map) => {
      const div = Leaflet.DomUtil.create('div', 'map-legend');
      div.innerHTML += '<h4>Legenda</h4>';
      for (let el of data) {
        div.innerHTML += `<i style="background: ${el.color}"></i><span>${el.name}</span><br>`;
      }
      return div;
    };
    this.leafletLegend = legendDiv.addTo(this.leafLetMap);
  }
}
