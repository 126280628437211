import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PROCESS_STATUS, ProcessListFilter } from '../../../../model/process-list-filter';
import { ProcessesDataService } from '../processes-data-service';
import { Person, Site, SiteEntry, SitePaging } from '@alfresco/js-api';
import { SitesService } from '@alfresco/adf-content-services';
import { map, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, Subscription } from 'rxjs';
import { PlanService } from '../../../form/services/plan.service';
import { Store } from '@ngrx/store';
import { getUserProfile } from '@alfresco/aca-shared/store';

/** Process definitions (BPMN) which are handled by this ADF application
 * Name translation can be obtained using: this.translation.instant(`EPM.PROCESS.LABEL.${id.toUpperCase()}`)
 * */
export const PROCESS_DEFINITIONS_IDS = ['stdWorkflow', 'stdWorkflow2', 'issueWorkflow'];

@Component({
  selector: 'epm-process-filters',
  templateUrl: './processes-filter.component.html',
  styleUrls: ['./processes-filter.component.scss']
})
export class ProcessesFilterComponent implements OnInit, OnDestroy {
  @Output() filtersVisible: EventEmitter<boolean> = new EventEmitter();

  /** Expand  processes filter component */
  isExpanded = true;

  /** Filters form */
  filtersForm = this.fb.group({
    siteId: new FormControl('', []),
    definitionId: new FormControl('issueWorkflow', [Validators.required]),
    startedAfter: new FormControl<Date | null>(null),
    startedBefore: new FormControl<Date | null>(null),
    startedBy: new FormControl(''),
    assignedTo: new FormControl(''),
    status: new FormControl(PROCESS_STATUS.ACTIVE, Validators.required)
  });
  isStartedByOpen = false;
  isSmallScreen = false;

  /** Fetched sites to populate site filter */
  sites: Site[];
  readonly processDefinitionsIds = PROCESS_DEFINITIONS_IDS;
  private sitesSub: Subscription;
  private readonly onDestroy$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private processesDataService: ProcessesDataService,
    private sitesService: SitesService,
    private breakpointObserver: BreakpointObserver,
    private planService: PlanService,
    private store: Store
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      this.isSmallScreen = result.matches;
    });
    if (this.processesDataService.filterData) {
      this.filtersForm.setValue(this.processesDataService.filterData);
    }
    this._fetchSites();
    this.store
      .select(getUserProfile)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((profile) => {
        if (profile.id) {
          this.filtersForm.controls['startedBy'].setValue(profile.id);
        }
      });
    this.filtersVisible.emit(true);
    this.planService.setSelectedPlan(false);
  }

  onFiltersFormSubmit() {
    // when form is submitted
    if (this.filtersForm.valid) {
      const filtersObj = new ProcessListFilter(this.filtersForm.value);
      this.processesDataService.filters$.next(filtersObj);
      this.isExpanded = false;
      this.filtersVisible.emit(false);
    }
  }

  toggleStartedBySelect() {
    // hides/shows startedBy people search
    this.isStartedByOpen = !this.isStartedByOpen;
  }

  onStartedBySelect(person: Person) {
    // executed when startedBy user is selected
    this.filtersForm.patchValue({ startedBy: person.id });
    this.isStartedByOpen = false;
  }

  onReset() {
    this.filtersForm.reset({
      siteId: '',
      status: PROCESS_STATUS.ACTIVE
    });
  }

  onExpandedChange(expanded: boolean) {
    this.filtersVisible.emit(expanded);
  }

  ngOnDestroy() {
    this.processesDataService.filterData = new ProcessListFilter(this.filtersForm.value);
    this.sitesSub?.unsubscribe();
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private _fetchSites() {
    this.sitesSub = this.sitesService
      .getSites()
      .pipe(map((sitePaging: SitePaging) => sitePaging.list.entries.map((siteEntry: SiteEntry) => siteEntry.entry)))
      .subscribe((sites: Site[]) => (this.sites = sites));
  }

  getDateFormatString() {
    return 'DD/MM/YYYY';
  }
}
