import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldModel, FormService, WidgetComponent } from '@alfresco/adf-core';
import { DynamicListsDataModel } from '../../../../api/models/epm/dynamiclists.model';
import { of, Subject } from 'rxjs';
import { DlWorkflowService } from '../../workflow/data-list/dl-workflow.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { ProcessService } from '../../../../services/process.service';

@Component({
  selector: 'aca-dl-type',
  templateUrl: './dl-type.component.html',
  styleUrls: ['./dl-type.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class DlTypeComponent extends WidgetComponent implements OnInit, OnDestroy {
  /** Filtered process type by site id */
  siteProcessTypes: DynamicListsDataModel[];

  /** Fetched process types for the data-list workflows */
  processTypes: DynamicListsDataModel[];

  /** Filtered process types as user types for the autocomplete input */
  filteredProcessTypes: DynamicListsDataModel[] = [];

  /** selected process type */
  selectedProcessType: DynamicListsDataModel = null;

  fieldChanged$: Subject<boolean> = new Subject<boolean>();

  private readonly onDestroy$ = new Subject<boolean>();
  isProcessTypesLoading = false;
  constructor(
    public formService: FormService,
    private dlWorkflowService: DlWorkflowService,
    private processDynamicFormData: ProcessDynamicFormData,
    private processService: ProcessService
  ) {
    super(formService);
  }

  /** Do not render form again is user mistakenly change the value and then fix it */
  private prevValidValue: string = '';

  ngOnInit(): void {
    const wfDefModelName = this.processDynamicFormData?.wfDefModel?.name || 'activiti$issueWorkflow';
    if (wfDefModelName) {
      this.loadProcessTypes(wfDefModelName);
      this.fieldChanged$.pipe(takeUntil(this.onDestroy$)).subscribe((_) => {
        this.loadProcessTypes(wfDefModelName);
      });
      this.processDynamicFormData.choosenSiteId.pipe(takeUntil(this.onDestroy$)).subscribe((siteId) => {
        this.siteProcessTypes = this._filterProcessTypesBySiteId(siteId);
        this.filteredProcessTypes = this._filterProcessTypes(this.field.value || '');
      });
    }
    this.prevValidValue = this.field.value;
  }

  dlProcessTypesAutoOptionDisplay(processTypeValue: string): string {
    return processTypeValue ? this.processTypes.find((_) => _.name === processTypeValue).name : '';
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onFieldChanged(field: FormFieldModel) {
    if (field.value && field.value === this.prevValidValue) {
      return;
    }
    super.onFieldChanged(field);
    this.filteredProcessTypes = this._filterProcessTypes(field.value || '');

    // check if field value is equal to any process type
    if (field.value) {
      for (let procType of this.processTypes) {
        if (procType.name === field.value) {
          this.prevValidValue = field.value;
          this.selectedProcessType = procType;
          this.dlProcessTypeSelectionChanged(procType);
        }
      }
    }
  }

  private loadProcessTypes(processBpmnId: string) {
    this.isProcessTypesLoading = true;
    this.dlWorkflowService
      .fetchWfTypes(processBpmnId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (model) => {
          this.processTypes = model.data;
          this.siteProcessTypes = this._filterProcessTypesBySiteId(this.processDynamicFormData.choosenSiteId.value);
          if (this.processDynamicFormData.choosenSiteId.value) {
            this.filteredProcessTypes = this.siteProcessTypes; // on first focus, show whole list of options
          } else {
            this.filteredProcessTypes = (<unknown>this.field.options) as DynamicListsDataModel[];
          }
          this.isProcessTypesLoading = false;

          const currentTypeNode = this.field.form.values['epmWf_dataListWfNode'];
          if (currentTypeNode) {
            this.selectedProcessType = this.processTypes.find((_) => _.ref === currentTypeNode);
            // this.dlProcessTypeSelectionChanged(this.selectedProcessType);
            this.field.value = this.selectedProcessType.name;
          }
        },
        (error) => {
          this.isProcessTypesLoading = false;
          console.log(error);
        }
      );
  }

  /**
   * Filter fetched process types by siteid
   * @param siteId
   * @private
   */
  private _filterProcessTypesBySiteId(siteId: string): DynamicListsDataModel[] {
    if (siteId && this.processTypes) {
      return this.processTypes.filter((type) => type.siteid === siteId);
    }
    return this.processTypes;
  }

  /**
   * Filter site process types by the value typed by a user
   * @param value
   * @private
   */
  private _filterProcessTypes(value: string): DynamicListsDataModel[] {
    if (this.siteProcessTypes && value !== null && value !== undefined) {
      const filterValue = value.toLowerCase();
      return this.siteProcessTypes.filter((type) => type.name.toLowerCase().includes(filterValue));
    }
    return [];
  }

  private dlProcessTypeSelectionChanged(processType: DynamicListsDataModel) {
    this.fieldChanged$.next(true);
    this.processDynamicFormData.formModel
      .pipe(
        take(1),
        switchMap((formModel) => {
          const taskId = formModel.taskId;
          if (taskId) {
            return this.processService.getTaskVariables(taskId).pipe(
              take(1),
              map((taskVariables) => taskVariables['taskFormKey'])
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe((taskFormKey) => {
        if (processType) {
          //render process type form
          if (taskFormKey !== 'epmIssueWf:resubmit') {
            this.processDynamicFormData.renderer.onProcessTypeSelect(processType);
          } else {
            // change process type for the resubmit form
            this.processDynamicFormData.renderer.onProcessTypeChange(processType);
          }
        }
      });
  }
  event($event: Event): void {
    if ($event.type === 'focus' || $event.type === 'focusin' || $event.type === 'click') {
      $event.preventDefault();
    }
  }
}
