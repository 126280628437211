import { Injectable } from '@angular/core';
import { ContentClient, RequestOptions } from '@alfresco/js-api';
import { throwIfNotDefined } from '../utils/assert';
import { DynamiclistsModel } from '../models/epm/dynamiclists.model';
import { RetrieveUxDataModel } from '../models/epm/retrieve-ux-data.model';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { WorkflowDefinitionsModel } from '../models/legacy/workflow-definitions.model';
import { DatalistsDataModel } from '../models/legacy/datalists-data.model';
import { EpmIssuePlanModel } from '../models/legacy/epm-issue-plan.model';
import { EpmIssueModel } from '../models/legacy/epm-issue-issue.model';
import { EpmIssuePhotosResponseModel } from '../models/legacy/epm-issue-photos.response.model';
import { IssueRequestFilterModel } from '../models/legacy/issue-request-filter.model';
import { HttpParams } from '@angular/common/http';

export const STD_WORKFLOW_DATA_LIST_ITEM = 'stdWf:stdWorkflowDataListItem';

@Injectable({
  providedIn: 'root'
})
export class LegacyApiService {
  private readonly legacyClient: ContentClient;

  constructor(private apiService: AlfrescoApiService) {
    if (!this.legacyClient) {
      this.legacyClient = new ContentClient(apiService.getInstance().config, '/wcs', apiService.getInstance().httpClient);
    } else {
      this.legacyClient.setConfig(apiService.getInstance().config, '/wcs');
    }
    this.legacyClient.setAuthentications(this.apiService.getInstance().contentClient.authentications);
  }

  // TODO check if returnType works correctly (it should automatically create an Object, more info: superagentHttpClient.ts method deserialize)

  fetchWfTypes(dataListItem: string): Promise<DynamiclistsModel> {
    throwIfNotDefined(dataListItem, 'dataListItem');
    const options: RequestOptions = {
      path: 'epmflow/dynamiclists/' + dataListItem,
      returnType: DynamiclistsModel
    };
    return this.legacyClient.get<DynamiclistsModel>(options);
  }

  retrieveUxData(dataListRef: string, dlTaskDefNodeRef?: string): Promise<RetrieveUxDataModel> {
    throwIfNotDefined(dataListRef, 'dataListRef');
    const options: RequestOptions = {
      path: 'epmflow/stdWf/retrieveUXData',
      queryParams: { nodeRef: dataListRef },
      returnType: RetrieveUxDataModel
    };
    if (dlTaskDefNodeRef) {
      options.queryParams['dlTaskDefNodeRef'] = dlTaskDefNodeRef;
    }
    return this.legacyClient.get<RetrieveUxDataModel>(options);
  }

  getProcessDefinitions(exclude?: string[]) {
    const options: RequestOptions = {
      path: 'api/workflow-definitions'
    };
    if (exclude && exclude.length > 0) {
      options.queryParams = { exclude: exclude.join(',') };
    }
    return this.legacyClient.get<WorkflowDefinitionsModel>(options);
  }

  getProcessDefinitionSimpleImage(processInstanceId: string): Promise<any> {
    throwIfNotDefined(processInstanceId, 'processInstanceId');
    const options: RequestOptions = {
      path: `epmflow/datalists/workflow-instance/activiti$${processInstanceId}/diagram`,
      contentTypes: ['application/x-www-form-urlencoded'],
      accepts: ['image/png'],
      responseType: 'blob'
    };
    return this.legacyClient.get<any>(options);
  }

  /**
   * Data Lists Component - retrieve data within a given list
   *
   */

  /**
   * Get datalist records' fields
   * @param datalistNodeRef
   * @param fields fields which should be returned i.e. ["epmWf_taskIndex","epmWf_taskId"]
   * @param filter if null or "all", all datalist folder children are parsed, else, filter is passed as search.query() i.e. {"filterId":"all","filterData":""}
   */
  getDatalistData(datalistNodeRef: string, fields: string[], filter: { filterId: string; filterData: string }): Promise<DatalistsDataModel> {
    throwIfNotDefined(datalistNodeRef, 'datalistNodeRef');

    const options: RequestOptions = {
      path: `slingshot/datalists/data/node/${datalistNodeRef.replace('://', '/')}`,
      bodyParam: {
        fields: fields,
        filter: filter
      },
      returnType: DatalistsDataModel
    };
    return this.legacyClient.post<DatalistsDataModel>(options);
  }

  /**
   * Get issue plans for the @siteId site
   * @param siteId
   */
  getIssuePlans(siteId: string): Promise<{ plans: EpmIssuePlanModel[] }> {
    throwIfNotDefined(siteId, 'siteId');

    const options: RequestOptions = {
      path: `epmflow/issue/plans?siteId=${siteId}`,
      returnType: EpmIssuePlanModel
    };
    return this.legacyClient.get<any>(options);
  }

  getIssuePlanIssues(
    planNodeRef: string,
    completed?: boolean,
    startedAfter?: Date,
    startedBefore?: Date,
    startedBy?: string,
    priority?: string,
    category?: string,
    departments?: string[],
    currAssignedTo?: string,
    type?: string
  ): Promise<{ issues: EpmIssueModel[] }> {
    throwIfNotDefined(planNodeRef, 'planNodeRef');

    let queryParams = new HttpParams().set('planRef', planNodeRef);

    if (completed !== undefined) {
      queryParams = queryParams.set('completed', completed);
    }
    if (startedAfter) {
      queryParams = queryParams.set('startedAfter', startedAfter.toISOString());
    }
    if (startedBefore) {
      queryParams = queryParams.set('startedBefore', startedBefore.toISOString());
    }
    if (startedBy) {
      queryParams = queryParams.set('startedBy', startedBy);
    }
    if (priority) {
      queryParams = queryParams.set('priority', priority);
    }
    if (category) {
      queryParams = queryParams.set('category', category);
    }
    if (departments) {
      queryParams = queryParams.set('departments', departments.join(','));
    }
    if (currAssignedTo) {
      queryParams = queryParams.set('currAssignedTo', currAssignedTo);
    }
    if (type) {
      queryParams = queryParams.set('type', type);
    }

    const options: RequestOptions = {
      path: `epmflow/issue/issues?${queryParams.toString()}`,
      returnType: EpmIssueModel
    };
    return this.legacyClient.get<any>(options);
  }

  getIssuePhotosContainer(siteid: string): Promise<EpmIssuePhotosResponseModel> {
    throwIfNotDefined(siteid, 'siteid');

    const options: RequestOptions = {
      path: `epmflow/issue/photos/site/${siteid}`,
      returnType: EpmIssuePhotosResponseModel
    };
    return this.legacyClient.get<any>(options);
  }

  generateIssueReport(body?: IssueRequestFilterModel): Promise<any> {
    throwIfNotDefined(body, 'body');

    return this.legacyClient.callApi('epmflow/issue/plans/generate', 'POST', {}, {}, {}, {}, body, ['application/json'], ['application/json']);
  }
}
