<mat-accordion displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0" [expanded]="isExpanded" (opened)="isExpanded=true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>assessment</mat-icon>
        <span>{{ 'EPM.LAYOUT.FILTERS' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <form [formGroup]="filtersForm"
            (ngSubmit)="onFiltersFormSubmit()"
            fxLayout="column" fxLayoutGap="10px">
        <div formGroupName="planFormGroup">
          <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.PROCESS_LIST.FILTERS.SITE_ID' | translate }}</mat-label>
              <mat-select
                formControlName="site"
                style="line-height: initial">
                <mat-option [value]="''">{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}</mat-option>
                <mat-option *ngFor="let site of sites" [value]="site.id">{{ site.title }}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.FORM.PLAN' | translate }}</mat-label>
            <mat-select
              formControlName="plan"
              style="line-height: initial">
              <mat-option [value]="''">{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}</mat-option>
              <mat-option *ngFor="let plan of plans" [value]="plan.nodeRef">{{ plan.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div formGroupName="issuesFormGroup">
          <div>
            <mat-form-field fxFlex style="margin-top: 1em">
              <mat-label>{{ 'EPM.LABELS.STATUS' | translate }}</mat-label>
              <mat-select
                formControlName="status"
                style="line-height: initial">
                <mat-option [value]="'any'">{{ 'EPM.FORM.TASK_STATUS.ANY' | translate }}</mat-option>
                <mat-option [value]="'completed'">{{ 'EPM.FORM.TASK_STATUS.COMPLETED' | translate }}</mat-option>
                <mat-option [value]="'active'">{{ 'EPM.FORM.TASK_STATUS.ACTIVE' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field fxFlex style="margin-top: 1em">
              <mat-label>{{ 'EPM.LABELS.TYPE' | translate }}</mat-label>
              <mat-select
                formControlName="type"
                style="line-height: initial">
                <mat-option [value]="'any'">{{ 'EPM.FORM.TYPE.ANY' | translate }}</mat-option>
                <mat-option *ngFor="let type of types" [value]="type.ref">{{ type.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.LABELS.PRIORITY' | translate }}</mat-label>
              <mat-select
                formControlName="priority"
                style="line-height: initial">
                <mat-option>{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}</mat-option>
                <mat-option [value]="1">{{ 'EPM.FORM.PRIORITY.1' | translate }}</mat-option>
                <mat-option [value]="2">{{ 'EPM.FORM.PRIORITY.2' | translate }}</mat-option>
                <mat-option [value]="3">{{ 'EPM.FORM.PRIORITY.3' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <!-- <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.FORM.VAR.EPMISSUEWF.CATEGORY' | translate }}</mat-label>
              <mat-select
                formControlName="category"
                style="line-height: initial">
                <mat-option>{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}</mat-option>
                <mat-option [value]="'notApplicable'">{{ 'EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.NOTAPPLICABLE' | translate }}</mat-option>
                <mat-option [value]="'acceptance'">{{ 'EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.ACCEPTANCE' | translate }}</mat-option>
                <mat-option [value]="'defect'">{{ 'EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.DEFECT' | translate }}</mat-option>
                <mat-option [value]="'fault'">{{ 'EPM.FORM.VAR.EPMISSUEWF.CATEGORY_OPTIONS.FAULT' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <!-- BRANŻE -->
          <!-- <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT' | translate }}</mat-label>
              <mat-select multiple
                formControlName="departments"
                style="line-height: initial">
                <mat-option [value]="'architecture'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.ARCHITECTURE' | translate }}</mat-option>
                <mat-option [value]="'construction'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.CONSTRUCTION' | translate }}</mat-option>
                <mat-option [value]="'electrical'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.ELECTRICAL' | translate }}</mat-option>
                <mat-option [value]="'lowCurrent'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.LOWCURRENT' | translate }}</mat-option>
                <mat-option [value]="'sanitary'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.SANITARY' | translate }}</mat-option>
                <mat-option [value]="'road'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.ROAD' | translate }}</mat-option>
                <mat-option [value]="'railway'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.RAILWAY' | translate }}</mat-option>
                <mat-option [value]="'bridge'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.BRIDGE' | translate }}</mat-option>
                <mat-option [value]="'hydraulic'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.HYDRAULIC' | translate }}</mat-option>
                <mat-option [value]="'technological'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.TECHNOLOGICAL' | translate }}</mat-option>
                <mat-option [value]="'landscape'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.LANDSCAPE' | translate }}</mat-option>
                <mat-option [value]="'networks'">{{ 'EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.NETWORKS' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.LABELS.STARTED_BEFORE' | translate }}</mat-label>
              <input
                matInput [matDatepicker]="taskStartedBeforeDatePicker"
                formControlName="startedBefore">
              <mat-hint>{{getDateFormatString()}}</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="taskStartedBeforeDatePicker">
              </mat-datepicker-toggle>
              <mat-datepicker
                #taskStartedBeforeDatePicker
                [touchUi]="isSmallScreen">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.LABELS.STARTED_AFTER' | translate }}</mat-label>
              <input [max]="currentDate"
                     matInput [matDatepicker]="taskStartedAfterDatePicker"
                     formControlName="startedAfter">
              <mat-hint>{{getDateFormatString()}}</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="taskStartedAfterDatePicker">
              </mat-datepicker-toggle>
              <mat-datepicker
                #taskStartedAfterDatePicker
                [touchUi]="isSmallScreen">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex (click)="toggleStartedBySelect()">
              <input matInput readonly
                     id="startedBy_id"
                     placeholder="{{ 'EPM.PROCESS_LIST.FILTERS.STARTED_BY' | translate }}"
                     formControlName="startedBy">
              <button *ngIf="filtersForm.get('issuesFormGroup').get('startedBy').value" matSuffix mat-icon-button aria-label="Clear" (click)="filtersForm.get('issuesFormGroup').get('startedBy').setValue('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <epm-people-search [showPeopleSearch]="isStartedByOpen" class="people-search-field"
                               (userSelect)="onStartedBySelect($event)">
            </epm-people-search>
          </div>
          <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex (click)="toggleCurrAssignedToSelect()">
              <input matInput readonly
                     id="currAssignedTo_id"
                     placeholder="{{ 'EPM.PROCESS_LIST.FILTERS.ASSIGNED_TO' | translate }}"
                     formControlName="currAssignedTo">
              <button *ngIf="filtersForm.get('issuesFormGroup').get('currAssignedTo').value" matSuffix mat-icon-button aria-label="Clear" (click)="filtersForm.get('issuesFormGroup').get('currAssignedTo').setValue('')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <epm-people-search [showPeopleSearch]="isCurrAssignedToOpen" class="people-search-field"
                               (userSelect)="onCurrAssignedToSelect($event)">
            </epm-people-search>
          </div>
          <!-- KATEGORIA KOLORU MARKERA -->
          <!-- <div>
            <mat-form-field fxFlex>
              <mat-label>{{ 'EPM.FORM.MARKER_COLOR_BY.LABEL' | translate }}</mat-label>
              <mat-select
                formControlName="markerColorCategory"
                style="line-height: initial">
                <mat-option [value]="'status'">{{ 'EPM.FORM.MARKER_COLOR_BY.STATUS' | translate }}</mat-option>
                <mat-option [value]="'department'">{{ 'EPM.FORM.MARKER_COLOR_BY.DEPARTMENT' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
        </div>

        <div class="epm-filter-actions" align="end" fxLayoutGap="8px">
          <button mat-raised-button type="button" (click)="onReset()">{{ 'EPM.FORM.BUTTON.RESET' | translate }}</button>
          <button mat-raised-button color="primary" type="submit" [disabled]="disableSubmit()">{{ 'EPM.PROCESS_LIST.FILTERS.SUBMIT' | translate }}</button>
          <button mat-raised-button color="primary" type="button" (click)="onGeneratePdf()" [disabled]="!filtersForm.valid">{{ 'EPM.PROCESS_LIST.FILTERS.GENERATE_PDF' | translate }}</button>

        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>

