import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PROCESS_STATUS } from '../../../model/process-list-filter';
import { Person, Site, SiteEntry, SitePaging } from '@alfresco/js-api';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { SitesService } from '@alfresco/adf-content-services';
import { filter, map, take } from 'rxjs/operators';
import { LegacyContentService } from '../../../services/legacy-content.service';
import { EpmIssuePlanModel } from '../../../api/models/legacy/epm-issue-plan.model';
import { Store } from '@ngrx/store';
import { AppStore, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { PlanFilerModel } from './plan-filer.model';
import { TranslateService } from '@ngx-translate/core';
import { LegacyApiService } from '../../../api/services/legacy-api.service';
import { DynamiclistsModel } from '../../../api/models/epm/dynamiclists.model';

@Component({
  selector: 'epm-issues-filter',
  templateUrl: './plan-filter.component.html',
  styleUrls: ['./plan-filter.component.scss']
})
export class PlanFilterComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Emitted  on filters submit.
   */
  @Output() filters: EventEmitter<PlanFilerModel> = new EventEmitter<PlanFilerModel>();

  /** Sites list for the site select options */
  sites: Site[];

  /** Site plans list for the plan select options  */
  plans: EpmIssuePlanModel[];

  /** Expand  processes filter component */
  isExpanded = true;

  /** Filters form */
  filtersForm = this.fb.group({
    planFormGroup: this.fb.group({
      site: new FormControl('', Validators.required),
      plan: new FormControl('')
    }),
    issuesFormGroup: this.fb.group({
      // priority: new FormControl<number | null>(null, []),
      // category: new FormControl<string>('', []),
      departments: new FormControl<string[]>(null),
      startedBefore: new FormControl<Date | null>(null),
      startedAfter: new FormControl<Date | null>(null),
      startedBy: new FormControl(''),
      currAssignedTo: new FormControl(''),
      status: new FormControl(PROCESS_STATUS.ACTIVE),
      markerColorCategory: new FormControl('status'),
      type: new FormControl('any')
    })
  });
  types: { name: string; ref: string }[] = [];
  currentDate = new Date();

  isStartedByOpen = false;
  isCurrAssignedToOpen = false;

  isSmallScreen: boolean = false;

  private sitesSub: Subscription;
  private plansSub: Subscription;
  private siteInputChangeSub: Subscription;

  constructor(
    private fb: FormBuilder,
    breakpointObserver: BreakpointObserver,
    private sitesService: SitesService,
    private legacyApi: LegacyContentService,
    private store: Store<AppStore>,
    private translateService: TranslateService,
    private legacyApiService: LegacyApiService
  ) {
    breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      this.isSmallScreen = result.matches;
    });
  }

  ngOnInit(): void {
    this._fetchSites();

    this.siteInputChangeSub = this.filtersForm
      .get('planFormGroup')
      .get('site')
      .valueChanges.pipe(filter(Boolean))
      .subscribe((value: string) => this._fetchPlans(value));

    this.legacyApiService.fetchWfTypes('epmIssueWf:issueWorkflowDataListItem').then((dynamicList) => {
      this.types = this._filterProcessTypesBySiteId(dynamicList).map((type) => ({ name: type.name, ref: type.name }));
      this.filtersForm
        .get('planFormGroup')
        .get('site')
        .valueChanges.pipe
        // takeUntil(this.onDestroy$),
        ()
        .subscribe((_) => {
          this.types = this._filterProcessTypesBySiteId(dynamicList).map((type) => ({ name: type.name, ref: type.name }));
        });
    });
  }

  private _filterProcessTypesBySiteId(processTypes: DynamiclistsModel) {
    const siteId = this.filtersForm.get('planFormGroup').get('site').value;
    if (siteId && this.types) {
      return processTypes.data.filter((type) => type.siteid === siteId);
    }
    return processTypes.data;
  }

  onReset() {
    this.filtersForm.reset({
      planFormGroup: {
        site: '',
        plan: ''
      },
      issuesFormGroup: {
        status: PROCESS_STATUS.ACTIVE,
        markerColorCategory: 'status',
        type: ''
      }
    });
  }

  toggleStartedBySelect() {
    // hides/shows startedBy people search
    this.isStartedByOpen = !this.isStartedByOpen;
  }

  toggleCurrAssignedToSelect() {
    // hides/shows currAssignedTo people search
    this.isCurrAssignedToOpen = !this.isCurrAssignedToOpen;
  }

  onStartedBySelect(person: Person) {
    // executed when startedBy user is selected
    this.filtersForm.patchValue({ issuesFormGroup: { startedBy: person.id } });
    this.isStartedByOpen = false;
  }

  onCurrAssignedToSelect(person: Person) {
    // executed when startedBy user is selected
    this.filtersForm.patchValue({ issuesFormGroup: { currAssignedTo: person.id } });
    this.isCurrAssignedToOpen = false;
  }

  onFiltersFormSubmit() {
    // when form is submitted
    if (this.filtersForm.valid) {
      this.filters.emit(this._getDataFromForm());
      this.isExpanded = false;
    }
  }

  getDateFormatString() {
    return 'DD/MM/YYYY';
  }

  onGeneratePdf() {
    // create request body
    const body = this._getDataFromForm().toIssueReportRequestModel();

    // call API to generate PDF
    this.legacyApi
      .generateIssueReport(body)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.store.dispatch(new SnackbarInfoAction(this.translateService.instant('EPM.BROWSE.PLANS.REPORT')));
        }
      });
  }

  ngOnChanges(_: SimpleChanges): void {
    // TODO change select options
  }

  ngOnDestroy() {
    this.sitesSub?.unsubscribe();
    this.plansSub?.unsubscribe();
    this.siteInputChangeSub?.unsubscribe();
  }

  disableSubmit(): boolean {
    return !this.filtersForm.valid || !this.filtersForm.get('planFormGroup').get('plan').value;
  }

  private _getDataFromForm(): PlanFilerModel {
    return new PlanFilerModel({
      planFormGroup: {
        site: this.filtersForm.get('planFormGroup').get('site').value,
        plan: this.filtersForm.get('planFormGroup').get('plan').value
      },
      issuesFormGroup: {
        // priority: this.filtersForm.get('issuesFormGroup').get('priority').value,
        // category: this.filtersForm.get('issuesFormGroup').get('category').value,
        departments: this.filtersForm.get('issuesFormGroup').get('departments').value,
        startedBefore: this.filtersForm.get('issuesFormGroup').get('startedBefore').value,
        startedAfter: this.filtersForm.get('issuesFormGroup').get('startedAfter').value,
        startedBy: this.filtersForm.get('issuesFormGroup').get('startedBy').value,
        currAssignedTo: this.filtersForm.get('issuesFormGroup').get('currAssignedTo').value,
        status: this.filtersForm.get('issuesFormGroup').get('status').value,
        markerColorCategory: this.filtersForm.get('issuesFormGroup').get('markerColorCategory').value,
        type: this.filtersForm.get('issuesFormGroup').get('type').value
      }
    });
  }

  private _fetchSites() {
    this.sitesSub = this.sitesService
      .getSites()
      .pipe(map((sitePaging: SitePaging) => sitePaging.list.entries.map((siteEntry: SiteEntry) => siteEntry.entry)))
      .subscribe((sites: Site[]) => {
        this.sites = sites;
      });
  }

  private removeFileExtension(fileName: string): string {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  private _fetchPlans(siteid: string) {
    if (siteid) {
      this.plansSub = this.legacyApi.getIssuePlans(siteid).subscribe((plans) => {
        this.plans = plans.map((plan) => ({
          ...plan,
          name: this.removeFileExtension(plan.name)
        }));
      });
    }
  }
}
