import { RestVariable } from '@alfresco/js-api';

/**
 * REST API model
 */
export class TaskInstance {
  id: string;
  processDefinitionId: string;
  processId: string;
  name: string;
  description: string;
  startedAt: Date;
  endedAt: Date;
  dueAt: Date;
  owner: string;
  assignee: string;
  state: string;
  activityDefinitionId: string;
  priority: number;
  formResourceKey: string;
  candidates: string[];
  private readonly _variables?: RestVariable[];

  get variables() {
    if (!this._variables) {
      return undefined;
    }
    return this._variables.reduce((map, obj) => {
      map[obj.name] = obj.value;
      return map;
    }, {});
  }

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      if (input.variables) {
        this._variables = input._variables.map((variable) => {
          return new RestVariable(variable);
        });
      }
    }
  }
}
