import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessService } from '../../../services/process.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { TaskInstance } from '../../../api/models/task/task-instance';
import { FormBaseComponent, FormModel, FormOutcomeModel, TranslationService } from '@alfresco/adf-core';
import { RestFormModel } from '../../../api/models/rest-form-model/rest-form-model';
import { Item } from '../../../api/models/item/item';
import { ProcessDynamicFormData } from '../../../services/process-dynamic-form.data';
import { ProcessDynamicFormService } from '../../../services/process-dynamic-form.service';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { PlanService } from '../../form/services/plan.service';

@Component({
  selector: 'aca-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit, OnDestroy {
  isValidId = true;
  isLoading = true;

  /** If false, all form controls are read-only */
  editMode = true;

  taskId: string;
  taskInstance: TaskInstance;
  taskVariables: {};
  formModel: FormModel = new FormModel();
  taskRestFormModel: RestFormModel[];
  taskAttachments: Item[];
  private readonly onDestroy$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private processService: ProcessService,
    private processDynamicFormService: ProcessDynamicFormService,
    private processDynamicFormData: ProcessDynamicFormData,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private planService: PlanService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.reset();
      this.taskId = params['id'];
      this.editMode = params['mode'] === 'edit';
      this.load();
    });

    this.processDynamicFormData.outcomeClicked.pipe(takeUntil(this.onDestroy$)).subscribe((_) => {
      this.completeTaskForm();
    });
    this.processDynamicFormData.formModel
      .pipe(
        takeUntil(this.onDestroy$),
        map((formModel) => {
          if (formModel.id === 'issueWorkflow') {
            formModel.changeFieldVisibility('assoc_packageItems_added', false);
            formModel.changeFieldDisabled('bpm_workflowDescription', true);
          }
          return formModel;
        })
      )
      .subscribe((formModel) => (this.formModel = formModel));
  }

  goBack() {
    const plan = this.planService.selectedPlan$.getValue();
    if (plan) {
      this.router.navigate(['epm', 'plan']);
    } else {
      this.router.navigate(['epm', 'tasks']);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.processDynamicFormData.resetFormModel();
  }

  private initForm() {
    this.processDynamicFormService.createWorkflowEditTaskFormModel(
      this.taskInstance,
      this.taskVariables,
      this.taskRestFormModel,
      this.taskAttachments,
      !this.editMode
    );
  }

  private reset() {
    this.isValidId = true;
    this.taskInstance = null;
    this.formModel = new FormModel();
    this.taskVariables = {};
    this.taskRestFormModel = null;
  }

  editTaskVisible() {
    return !this.editMode && this.taskInstance?.state !== 'completed';
  }

  isTaskUnclaimed() {
    return this.taskInstance?.state === 'unclaimed';
  }

  private load() {
    this.isValidId = true;
    this.isLoading = true;
    // Get:
    // 1. Task instance for the main info
    // 2. Task variables for the task variables values
    // 3. Task form model for the i18n and form rendering
    // 4. Task attachments
    this.processService
      .getTask(this.taskId)
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((taskInstance: TaskInstance) => {
          if (this.editMode && taskInstance.state === 'completed') {
            return of([taskInstance, null, null, null]);
          }
          return forkJoin([
            this.processService.getTaskVariables(this.taskId),
            this.processService.getTaskFormModel(this.taskId),
            this.processService.getTaskAttachments(this.taskId)
          ]).pipe(map((data) => [taskInstance, ...data]));
        })
      )
      .subscribe(
        ([taskInstance, taskVariables, taskRestFormModel, taskAttachments]: [TaskInstance, {}, RestFormModel[], Item[]]) => {
          this.taskInstance = taskInstance;
          if (this.editMode && taskInstance.state === 'completed') {
            this.store.dispatch(new SnackbarInfoAction(this.translation.instant('EPM.TASK_EDIT.ERROR.TASK_ALREADY_COMPLETED'), { duration: 2000 }));
            this.stopEditing();
          } else {
            this.taskVariables = taskVariables;
            this.taskRestFormModel = taskRestFormModel;
            this.taskAttachments = taskAttachments;
            this.initForm();
          }
          this.isLoading = false;
        },
        () => (this.isValidId = false)
      );
  }

  onOutcomeClicked(outcome: FormOutcomeModel) {
    if (outcome.id === FormBaseComponent.SAVE_OUTCOME_ID) {
      this.store.dispatch(new SnackbarInfoAction(this.translation.instant('EPM.TASK_EDIT.ERROR.SAVE_NOT_IMPLEMENTED'), { duration: 2000 }));
    } else if (outcome.id === FormBaseComponent.COMPLETE_OUTCOME_ID) {
      this.completeTaskForm();
    } else if (outcome.id === FormBaseComponent.START_PROCESS_OUTCOME_ID) {
      this.completeTaskForm();
    } else if (outcome.id === '$cancel') {
      this.stopEditing();
    }
  }

  completeTaskForm() {
    this.isLoading = true;

    // firstly claim the task if ont claimed before (required for the permission to Complete the task)
    // secondly, handle attachments modifications and then complete the task
    if (this.isTaskUnclaimed()) {
      this.processService
        .claimTask(this.taskId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((_) => {
          this.modifyAttachmentsAndCompleteTask();
        });
    } else {
      this.modifyAttachmentsAndCompleteTask();
    }
  }

  modifyAttachmentsAndCompleteTask() {
    // convert task variables
    const formValues = Object.assign({}, this.formModel.values); // shallow copy
    const restTaskVars = [];
    Object.keys(formValues)
      .filter((key) => key !== 'epmWf_comments')
      .forEach((key) => {
        restTaskVars.push({
          name: key,
          scope: 'local',
          value: Array.isArray(formValues[key]) ? formValues[key].join(',') : formValues[key]
        });
      });

    // prepare attachment actions
    const currentAttachments: string[] = formValues['assoc_packageItems_added']
      ? Array.isArray(formValues['assoc_packageItems_added'])
        ? formValues['assoc_packageItems_added'].map((i) => i.id)
        : formValues['assoc_packageItems_added'].split(',')
      : [];
    const deletedAttachments = this.taskAttachments.filter((item) => currentAttachments.find((currItem) => currItem === item.id) === undefined);
    const addedAttachments = currentAttachments.filter((currItem) => this.taskAttachments.find((item) => currItem === item.id) === undefined);
    const attachmentActions$: Observable<any>[] = [];
    deletedAttachments.forEach((item) => attachmentActions$.push(this.processService.removeTaskAttachments(this.taskId, item.id)));
    if (addedAttachments.length > 0) {
      attachmentActions$.push(this.processService.addTaskAttachments(this.taskId, addedAttachments));
    }
    attachmentActions$.push(of([]));

    // first try to add/delete attachments, then complete a task
    forkJoin(...attachmentActions$)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (_) => {
          // after successfully attachment modification, finally complete the task
          this.processService
            .completeTask(this.taskId, restTaskVars)
            .pipe(
              takeUntil(this.onDestroy$),
              catchError((_) => {
                // rollback attachment changes
                const restoreAttachments$: Observable<any>[] = [];
                if (deletedAttachments && deletedAttachments.length !== 0) {
                  restoreAttachments$.push(
                    this.processService.addTaskAttachments(
                      this.taskId,
                      deletedAttachments.map((i) => i.id)
                    )
                  );
                }
                addedAttachments.forEach((i) => restoreAttachments$.push(this.processService.removeTaskAttachments(this.taskId, i)));
                this.isLoading = false;
                return forkJoin(restoreAttachments$);
              })
            )
            .subscribe(
              (_) => {
                this.stopEditing(true);
                this.isLoading = false;
              },
              (_) => {
                this.isLoading = false;
              }
            );
        },
        (error) => {
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('EPM.TASK_EDIT.ERROR.MODIFY_ATTACHMENTS', { error: error })));
        }
      );
  }

  startEditing() {
    this.router.navigate(['epm/task', this.taskId, 'edit']);
  }

  stopEditing(backToPlan = false) {
    const plan = this.planService.selectedPlan$.getValue();
    if (plan && backToPlan) {
      this.router.navigate(['epm', 'plan']);
    } else {
      this.router.navigate(['epm/task', this.taskId, 'view']);
    }
  }

  onWorkflowDetailsClicked() {
    if (this.taskInstance.processDefinitionId) {
      this.router.navigate(['epm/process', this.taskInstance.processId]);
    }
  }
}
