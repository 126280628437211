import { Observable } from 'rxjs';
import { FormModel, FormVariableModel, TranslationService } from '@alfresco/adf-core';
import { map } from 'rxjs/operators';
import { RestFormModel } from '../../../api/models/rest-form-model/rest-form-model';
import { Item } from '../../../api/models/item/item';
import { TaskInstance } from '../../../api/models/task/task-instance';
import { DynamicListsDataModel } from '../../../api/models/epm/dynamiclists.model';
import { createFormFieldContainerJson } from '../utils/form';
import { AttachmentMode } from '../widgets/attach-file/attach-file-widget-component.component';

/**
 * Contains abstract methods which defines how the workflow form should look like.
 * Should be extended by every workflow type.
 */
export abstract class WorkflowFormRenderer {
  abstract get id(): string;
  abstract get name(): string;
  abstract get description(): string;

  protected constructor(protected translation: TranslationService) {}

  /**
   * Create start form.
   * @param formFields
   * @param readOnly
   */
  createStartForm(formFields: RestFormModel[], readOnly = false): Observable<FormModel> {
    return this.createStartFormFields(formFields).pipe(
      map((fields) => {
        const json = {
          id: this.id,
          name: this.name,
          description: this.description,
          fields: fields
        };
        return new FormModel(json, null, readOnly);
      })
    );
  }

  /**
   * Create task form.
   * @param taskInstance
   * @param variables
   * @param formFields
   * @param attachmentItems
   * @param readOnly
   */
  createTaskForm(
    taskInstance: TaskInstance,
    variables: object,
    formFields: RestFormModel[],
    attachmentItems: Item[],
    readOnly?: boolean
  ): Observable<FormModel> {
    return this.createTaskFormFields(taskInstance, variables, formFields, attachmentItems).pipe(
      map((fields) => {
        const json = {
          id: this.id,
          name: this.name,
          description: this.description,
          taskId: taskInstance.id,
          taskName: taskInstance.name,
          processDefinitionId: taskInstance.processDefinitionId,
          fields: fields,
          variables: convertVariablesToFormVariableModel(variables),
          outcomes: [
            {
              id: '$cancel',
              name: 'CANCEL'
            }
          ]
        };
        return new FormModel(json, null, readOnly);
      })
    );
  }

  /**
   * Create start form fields.
   * @param formFields
   */
  abstract createStartFormFields(formFields: RestFormModel[]): Observable<object[]>;

  /**
   * Create task form fields.
   * @param taskInstance
   * @param variables
   * @param formFields
   * @param attachmentItems
   */
  abstract createTaskFormFields(
    taskInstance: TaskInstance,
    variables: object,
    formFields: RestFormModel[],
    attachmentItems: Item[]
  ): Observable<object[]>;

  /**
   * Generate type specific form fields.
   * Called when use selected a process type.
   * Note: Use with the data-list workflows.
   * @param processType
   */
  onProcessTypeSelect(processType: DynamicListsDataModel) {
    console.log(processType);
  }

  onProcessTypeChange(processType: DynamicListsDataModel) {
    console.log(processType);
  }

  protected jsonEpmWfComments(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson(
      'epmWf_comments',
      this.translation.instant('EPM.FORM.VAR.EPMWF.COMMENTS'),
      'epmwf-comments',
      value,
      required,
      null,
      readOnly
    );
  }

  protected jsonBpmWorkflowDescription(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson(
      'bpm_workflowDescription',
      this.translation.instant('EPM.FORM.VAR.BPM.WORKFLOWDESCRIPTION'),
      readOnly ? 'readonly' : 'epm-text-datalist',
      value,
      required,
      // null,
      [
        { id: '1', name: 'Zabrudzenie' },
        { id: '2', name: 'Pęknięcie' },
        { id: '3', name: 'Uszkodzenie' },
        { id: '4', name: 'Zarysowanie' }
      ],
      readOnly
    );
  }

  protected jsonBpmComment(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson(
      'bpm_comment',
      this.translation.instant('EPM.FORM.VAR.BPM.COMMENT'),
      'multi-line-text',
      value ? value : '',
      required,
      null,
      readOnly
    );
  }

  protected jsonBpmPriority(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson(
      'bpm_workflowPriority',
      this.translation.instant('EPM.FORM.STDWORKFLOW.PRIORITY.LABEL'),
      'epm-dropdown',
      value ? value : '2',
      required,
      [
        {
          id: '3',
          name: this.translation.instant('EPM.FORM.STDWORKFLOW.PRIORITY.LOW')
        },
        {
          id: '2',
          name: this.translation.instant('EPM.FORM.STDWORKFLOW.PRIORITY.MEDIUM')
        },
        {
          id: '1',
          name: this.translation.instant('EPM.FORM.STDWORKFLOW.PRIORITY.HIGH')
        }
      ],
      readOnly
    );
  }

  protected jsonNextTransition() {
    return createFormFieldContainerJson(
      'transitions',
      this.translation.instant('EPM.FORM.VAR.TRANSITIONS'),
      'outcome-buttons',
      null,
      false,
      [{ id: 'next', name: this.translation.instant('EPM.FORM.OUTCOME.NEXT') }],
      false
    );
  }

  protected jsonAssocPackageItemsAdded(packageNodeRef?: string, attachmentItems?: Item[], mode?: AttachmentMode) {
    return {
      fieldType: 'ContainerRepresentation',
      name: 'Container - assoc_packageItems_added',
      type: 'container',
      fields: {
        1: [
          {
            fieldType: 'AttachFileFieldRepresentation',
            id: 'assoc_packageItems_added',
            name: this.translation.instant('EPM.FORM.VAR.ATTACHMENTS'),
            type: 'epm-upload',
            value: null,
            required: false,
            readOnly: mode === AttachmentMode.NONE,
            overrideId: false,
            colspan: 1,
            params: {
              existingColspan: 1,
              maxColspan: 2,
              fileSource: {
                serviceId: 'all-file-sources',
                name: 'All file sources'
              },
              link: true,
              multiple: 'multiple',
              packageNodeRef: packageNodeRef,
              attachmentItems: attachmentItems,
              menuOptions: {
                show: true,
                download: true,
                retrieveMetadata: false,
                remove: mode !== AttachmentMode.NONE
              }
            },
            visibilityCondition: null
          }
        ]
      }
    };
  }
}

function convertVariablesToFormVariableModel(variables: object): FormVariableModel[] {
  const variableModelList: FormVariableModel[] = [];
  if (variables) {
    Object.keys(variables).forEach((k) =>
      variableModelList.push({
        id: k,
        name: k,
        type: 'TODO',
        value: variableModelList[k]
      })
    );
  }
  return variableModelList;
}
