import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Person } from '@alfresco/js-api';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProcessService } from '../../../../services/process.service';
import { WorkflowDefinitionModel } from '../../../../api/models/legacy/workflow-definition.model';
import { TASKS_STATUS, TasksQueryRequestData } from '../../../../api/models/task/tasks-query.model';
import { TasksDataService } from '../tasks-data.service';
import { Store } from '@ngrx/store';
import { AppStore, getUserProfile } from '@alfresco/aca-shared/store';

@Component({
  selector: 'epm-task-filter',
  templateUrl: './tasks-filter.component.html',
  styleUrls: ['./tasks-filter.component.scss']
})
export class TasksFilterComponent implements OnInit, OnDestroy {
  isExpanded = false;
  isOpenedFirstTime = true;
  taskFilterForm: FormGroup = new FormGroup({
    processDefinitionKey: new FormControl(''),
    status: new FormControl(TASKS_STATUS.ACTIVE),
    assignee: new FormControl(''),
    priority: new FormControl<number>(null, [Validators.min(1), Validators.max(3)]),
    startedBefore: new FormControl(null),
    startedAfter: new FormControl(null)
  });

  currentDate = new Date();
  isAssigneeSearchOpen = false;
  processDefs: WorkflowDefinitionModel[];
  filteredProcessDefs: WorkflowDefinitionModel[];

  private readonly onDestroy$ = new Subject<boolean>();
  constructor(private processService: ProcessService, public tasksDataService: TasksDataService, private store: Store<AppStore>) {}

  ngOnInit(): void {
    this.restoreState();
    this.filterProcessDefinitionKey.valueChanges.pipe(debounceTime(500), takeUntil(this.onDestroy$)).subscribe((value) => {
      this.filteredProcessDefs = this._filterProcessDefs(value);
    });

    // if it is opened first time, auto-populate user, otherwise keep assignee value from state
    if (this.isOpenedFirstTime) {
      // Get user to auto populate assignee filter to create "My Tasks"
      this.store
        .select(getUserProfile)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((profile) => {
          if (profile.id) {
            this.filterAssignee.setValue(profile.id);
            this.tasksDataService.filters$.next(this.createTaskQueryRequestData());
          }
        });
    } else {
      this.tasksDataService.filters$.next(this.createTaskQueryRequestData());
    }

    this._setFilterProcessDefinitionKey('activiti$issueWorkflow');
  }

  onFiltersFormSubmit() {
    if (this.taskFilterForm.valid) {
      const tasksQuery = this.createTaskQueryRequestData();
      this.tasksDataService.filters$.next(tasksQuery);
      this.isExpanded = false;
    }
  }

  private createTaskQueryRequestData() {
    const tasksQuery = new TasksQueryRequestData();
    if (this.filterProcessDefinitionKey.value) {
      tasksQuery.processDefinitionKey = this.filterProcessDefinitionKey.value;
    }
    if (this.filterStatus.value) {
      tasksQuery.status = this.filterStatus.value;
    }
    if (this.filterAssignee.value) {
      tasksQuery.assignee = this.filterAssignee.value;
    }
    if (this.filterPriority.value) {
      tasksQuery.priority = this.filterPriority.value;
    }
    if (this.filterStartedBefore.value) {
      tasksQuery.startedBefore = this.filterStartedBefore.value;
    }
    if (this.filterStartedAfter.value) {
      tasksQuery.startedAfter = this.filterStartedAfter.value;
    }
    return tasksQuery;
  }

  onAssigneeSelect(person: Person) {
    this.taskFilterForm.patchValue({ assignee: person.id });
    this.isAssigneeSearchOpen = false;
  }

  resetForm() {
    this.taskFilterForm.reset({
      status: TASKS_STATUS.ACTIVE
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.tasksDataService.filterState = {
      taskFilterFormValue: this.taskFilterForm.value,
      processDefs: this.processDefs
    };
  }

  processDefAutoOptionDisplay(processDefValue: string): string {
    return processDefValue && this.processDefs ? this.processDefs.find((_) => _.name === processDefValue).title : '';
  }

  getDateFormatString(): string {
    return 'DD/MM/YYYY';
  }

  private _loadProcessDefs() {
    this.processService
      .getLegacyProcessDefinitions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((processDefs) => {
        this.processDefs = processDefs.data ? processDefs.data : [];
        this.filteredProcessDefs = this.processDefs;
      });
  }

  private _filterProcessDefs(value: string): any {
    if (this.processDefs) {
      const filterValue = value.toLowerCase();
      return this.processDefs.filter(
        (def) => def.name.toLowerCase().includes(filterValue) || (def.title && def.title.toLowerCase().includes(filterValue))
      );
    }
    return [];
  }

  private restoreState() {
    if (this.tasksDataService.filterState) {
      this.isOpenedFirstTime = false;
      const prevState = this.tasksDataService.filterState;
      if (prevState.taskFilterFormValue) {
        this.taskFilterForm.setValue(prevState.taskFilterFormValue);
      }
      if (prevState.processDefs) {
        this.processDefs = prevState.processDefs;
      }
      this.tasksDataService.filters$.next(this.createTaskQueryRequestData());
    } else {
      this._loadProcessDefs();
    }
  }

  private _setFilterProcessDefinitionKey(key: string) {
    this.filterProcessDefinitionKey.setValue(key);
  }

  get filterProcessDefinitionKey(): AbstractControl {
    return this.taskFilterForm.get('processDefinitionKey');
  }

  get filterStatus(): AbstractControl {
    return this.taskFilterForm.get('status');
  }

  get filterAssignee(): AbstractControl {
    return this.taskFilterForm.get('assignee');
  }

  get filterPriority(): AbstractControl {
    return this.taskFilterForm.get('priority');
  }

  get filterStartedAfter(): AbstractControl {
    return this.taskFilterForm.get('startedAfter');
  }

  get filterStartedBefore(): AbstractControl {
    return this.taskFilterForm.get('startedBefore');
  }
}
