import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as Leaflet from 'leaflet';
import { fromEvent, of, race, Subject, throwError } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { EpmIssueModel } from '../../../../../api/models/legacy/epm-issue-issue.model';

@Component({
  selector: 'epm-plan-map',
  templateUrl: './plan-map.component.html',
  styleUrls: ['./plan-map.component.scss']
})
export class PlanMapComponent implements OnInit, OnDestroy, OnChanges {
  /** Image URL from which map layer will be created */
  @Input() planUrl: string;

  /** Legend data list can be passed from parent */
  @Input() legendData: { color: string; name: string }[];

  /** Markers data list can be passed from parent */
  @Input() markersData: { issue: EpmIssueModel; icon: Leaflet.Icon }[];

  /** Emitted when user click on a map */
  @Output() mapClicked = new EventEmitter<Leaflet.LatLng>();

  /** Leaflet map object (initially empty) */
  leafLetMap!: Leaflet.Map;
  leafletOptions: Leaflet.MapOptions = {
    crs: Leaflet.CRS.Simple /** Simple coordinate system for the image */,
    center: [0, 0],
    zoom: -1,
    minZoom: -5,
    maxZoom: 4,
    doubleClickZoom: false,
    scrollWheelZoom: false
  };
  leafletLayers: Leaflet.Layer[] = [];

  private leafletMarkers: Leaflet.Marker[] = [];
  private leafletLegend: Leaflet.Control;

  private readonly onDestroy$ = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const newPlanUrl = changes['planUrl'];
    if (newPlanUrl && newPlanUrl.currentValue !== newPlanUrl.previousValue) {
      this._renderImageOnMap();
    }

    const newLegendData = changes['legendData'];
    if (newLegendData && newLegendData.currentValue !== newLegendData.previousValue) {
      this._renderLegend();
    }

    const newMarkersData = changes['markersData'];
    if (newMarkersData && newMarkersData.currentValue !== newMarkersData.previousValue) {
      this._renderMarkers();
    }
  }

  onMapReady(map: Leaflet.Map) {
    this.leafLetMap = map;

    this._renderImageOnMap();
    this._renderLegend();
    this._renderMarkers();
  }

  onMapClick(event: Leaflet.LeafletMouseEvent) {
    this.mapClicked.emit(new Leaflet.LatLng(event.latlng.lat, event.latlng.lng));
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private _renderMarkers() {
    if (this.leafLetMap) {
      // remove old markers
      this.leafletMarkers.forEach((marker) => this.leafLetMap.removeLayer(marker));
      this.leafletMarkers = [];

      if (!this.markersData || this.markersData.length === 0) {
        return;
      }

      // render new markers
      this.markersData.forEach((markerData) => {
        const marker = Leaflet.marker(new Leaflet.LatLng(markerData.issue.locationLat, markerData.issue.locationLng), {
          draggable: false,
          icon: markerData.icon
        });
        this.leafletMarkers.push(marker);
        marker.addTo(this.leafLetMap);
        if (markerData.issue.name) {
          // marker.bindPopup(`<b>${markerData.issue.name}</b><br>${markerData.issue.locationLat},  ${markerData.issue.locationLng}`);
        }
      });
    }
  }

  private _renderLegend() {
    // remove old legend
    if (this.leafletLegend) {
      this.leafLetMap.removeControl(this.leafletLegend);
    }

    if (!this.legendData || this.legendData.length === 0) {
      return;
    }

    // create new legend
    const legendDiv = new Leaflet.Control({ position: 'bottomleft' });
    legendDiv.onAdd = (_map) => {
      const div = Leaflet.DomUtil.create('div', 'map-legend');
      div.innerHTML += '<h4>Legenda</h4>';
      for (let el of this.legendData) {
        div.innerHTML += `<i style="background: ${el.color}"></i><span>${el.name}</span><br>`;
      }
      return div;
    };
    this.leafletLegend = legendDiv.addTo(this.leafLetMap);
  }

  private _renderImageOnMap() {
    if (this.planUrl && this.leafLetMap) {
      this.leafletLayers = []; // clear old layers, for the slow internet connection
      if (!this._isValidUrl(this.planUrl)) {
        return;
      }
      this._getImageMeta(this.planUrl)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((img) => {
          const w = img['width'];
          const h = img['height'];
          const bounds = this._createMapBounds(h, w);
          this.leafLetMap.setMaxBounds(bounds);
          this.leafletLayers = [Leaflet.imageOverlay(this.planUrl, bounds, {})];
          this.leafLetMap.setZoom(-3);
          // this is a crucial line, without it, the styles are broken
          setTimeout(() => this.leafLetMap.invalidateSize(true), 100);
        });
    }
  }

  private _getImageMeta(url) {
    return of(url).pipe(
      mergeMap((path) => {
        const img = new Image();
        let load = fromEvent(img, 'load').pipe(map((_) => img));
        let error = fromEvent(img, 'error').pipe(mergeMap((err) => throwError(() => err)));
        img.src = path;
        return race(load, error);
      })
    );
  }

  private _createMapBounds(imgHeight: number, imgWidth: number): Leaflet.LatLngBounds {
    const southWest = new Leaflet.LatLng(-imgHeight, 0);
    const northEast = new Leaflet.LatLng(0, imgWidth);
    return new Leaflet.LatLngBounds(southWest, northEast);
  }

  private _isValidUrl(urlString: string) {
    try {
      new URL(urlString);
      return true;
    } catch (err) {
      return false;
    }
  }
}
