import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormService, TranslationService, WidgetComponent } from '@alfresco/adf-core';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DatalistsDataModel } from '../../../../api/models/legacy/datalists-data.model';
import { LegacyContentService } from '../../../../services/legacy-content.service';

@Component({
  selector: 'epm-outcomes',
  templateUrl: './outcomes.component.html',
  styleUrls: ['./outcomes.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class OutcomesComponent extends WidgetComponent implements OnInit, OnDestroy {
  // private readonly MOVE_TO_OUTCOME_BUTTON_ID = 'epmWf_moveToOutcome';
  private changedStates: { [key: string]: 'enabled' | 'disabled' } = {};
  private buttonStateSub: Subscription;
  moveToTaskOptions = [];

  constructor(
    public formService: FormService,
    private processDynamicFormData: ProcessDynamicFormData,
    private legacyContentService: LegacyContentService,
    private translation: TranslationService
  ) {
    super(formService);
  }

  ngOnInit(): void {
    this.buttonStateSub = this.processDynamicFormData.changeButtonState.subscribe((data) => {
      if (data.buttonId && data.state) {
        this.changedStates[data.buttonId] = data.state;
      }
    });
    if (this.field.id === 'epmWf_moveToOutcome') {
      this.processDynamicFormData.formModel
        .pipe(
          switchMap((formModel) => {
            if (formModel.values['epmWf_dataListWfNode'] === undefined) {
              return of([]);
            }
            return this.legacyContentService
              .getDatalistData(formModel.values['epmWf_dataListWfNode'], ['epmWf_taskIndex', 'epmWf_taskId', 'epmWf_dynamicTaskNameConfig'], {
                filterId: 'all',
                filterData: ''
              })
              .pipe(
                map((dataListsDataModel: DatalistsDataModel) => {
                  const options: { id: string; name: string }[] = [
                    // { id: '-1', name: this.translation.instant('EPM.FORM.OPTION.END_WORKFLOW') },
                    { id: '0', name: this.translation.instant('EPM.FORM.OPTION.REFILL_THE_APPLICATION') }
                  ];
                  dataListsDataModel?.items?.map((item) => {
                    options.push({
                      id: item.itemData['prop_epmWf_taskIndex']?.value.toString(),
                      name: this.getTaskDynamicNameFromDataList(item)
                    });
                  });
                  return options;
                })
              );
          })
        )
        .subscribe((options) => {
          if (options) {
            this.moveToTaskOptions = options;
          }
        });
    }
  }

  protected getTaskDynamicNameFromDataList(item): string {
    return item.itemData['prop_epmWf_dynamicTaskNameConfig']
      ? item.itemData['prop_epmWf_dynamicTaskNameConfig'].displayValue
      : item.itemData['prop_epmWf_taskId']?.displayValue;
  }

  onOutcomeClicked(outcome: any) {
    this.field.value = outcome.id;
    this.processDynamicFormData.outcomeClicked.next(outcome);
  }

  isButtonDisabled(buttonId: string) {
    if (this.field.readOnly) {
      return true;
    }
    return !!this.changedStates[buttonId] && this.changedStates[buttonId] === 'disabled';
  }

  ngOnDestroy() {
    this.buttonStateSub?.unsubscribe();
  }

  selectMoveToTask(outcome: any, id: string) {
    this.field.value = outcome.id;
    const currValues = this.processDynamicFormData.lastFormModelValue.values;
    this.processDynamicFormData.updateFormFieldsModel([], { ...currValues, epmWf_moveToTaskIndex: id, epmWf_moveToOutcome: outcome.id });
    this.onOutcomeClicked(outcome);
  }
}
