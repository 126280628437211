<mat-accordion displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0" [expanded]="isExpanded" (opened)="isExpanded=true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>assessment</mat-icon>
        <span>{{ 'EPM.LAYOUT.FILTERS' | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <form [formGroup]="taskFilterForm"
            (ngSubmit)="onFiltersFormSubmit()"
            fxLayout="column" fxLayoutGap="10px">
        <!-- <div>
          <mat-form-field fxFlex floatLabel="always">
            <mat-label>{{ 'EPM.LABELS.PROCESS_DEFINITION_KEY' | translate }}</mat-label>
            <mat-select
              formControlName="processDefinitionKey"
              style="line-height: initial"
              placeholder="{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}">
              <mat-option *ngFor="let processDef of processDefs"
                          [value]="processDef.name">
                {{ processDef.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div>
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.LABELS.STATUS' | translate }}</mat-label>
            <mat-select
              formControlName="status"
              style="line-height: initial">
              <mat-option [value]="'any'">{{ 'EPM.FORM.TASK_STATUS.ANY' | translate }}</mat-option>
              <mat-option [value]="'completed'">{{ 'EPM.FORM.TASK_STATUS.COMPLETED' | translate }}</mat-option>
              <mat-option [value]="'active'">{{ 'EPM.FORM.TASK_STATUS.ACTIVE' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex (click)="isAssigneeSearchOpen=!isAssigneeSearchOpen">
            <mat-label>{{ 'EPM.LABELS.ASSIGNEE' | translate }}</mat-label>
            <input
                   readonly
                   matInput
                   formControlName="assignee">
            <button *ngIf="filterAssignee.value" matSuffix mat-icon-button aria-label="Clear" (click)="filterAssignee.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <epm-people-search [showPeopleSearch]="isAssigneeSearchOpen" class="people-search-field"
                             (userSelect)="onAssigneeSelect($event)">
          </epm-people-search>
        </div>
        <!-- <div>
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.LABELS.PRIORITY' | translate }}</mat-label>
            <mat-select
              formControlName="priority"
              style="line-height: initial">
              <mat-option>{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}</mat-option>
              <mat-option [value]="1">{{ 'EPM.FORM.PRIORITY.1' | translate }}</mat-option>
              <mat-option [value]="2">{{ 'EPM.FORM.PRIORITY.2' | translate }}</mat-option>
              <mat-option [value]="3">{{ 'EPM.FORM.PRIORITY.3' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div>
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.LABELS.STARTED_BEFORE' | translate }}</mat-label>
            <input
              matInput [matDatepicker]="taskStartedBeforeDatePicker"
              formControlName="startedBefore">
            <mat-hint>{{getDateFormatString()}}</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="taskStartedBeforeDatePicker">
            </mat-datepicker-toggle>
            <mat-datepicker
              #taskStartedBeforeDatePicker
              [touchUi]="tasksDataService.isSmallScreen">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.LABELS.STARTED_AFTER' | translate }}</mat-label>
            <input [max]="currentDate"
                   matInput [matDatepicker]="taskStartedAfterDatePicker"
                   formControlName="startedAfter">
            <mat-hint>{{getDateFormatString()}}</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="taskStartedAfterDatePicker">
            </mat-datepicker-toggle>
            <mat-datepicker
              #taskStartedAfterDatePicker
              [touchUi]="tasksDataService.isSmallScreen">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="epm-filter-actions" align="end">
          <button mat-raised-button type="button" (click)="resetForm()">{{ 'EPM.FORM.BUTTON.RESET' | translate }}</button>
          <button mat-raised-button color="primary" type="submit" [disabled]="!taskFilterForm.valid">{{ 'EPM.PROCESS_LIST.FILTERS.SUBMIT' | translate }}</button>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>

