/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MinimalNodeEntity } from '@alfresco/js-api';
import { ContentManagementService } from '../../services/content-management.service';
import { Store } from '@ngrx/store';
import { AppStore } from '@alfresco/aca-shared/store';
import { UploadService } from '@alfresco/adf-core';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppExtensionService, PageComponent } from '@alfresco/aca-shared';
import { DocumentListPresetRef, sortByOrder } from '@alfresco/adf-extensions';

@Component({
  templateUrl: './recent-files.component.html'
})
export class RecentFilesComponent extends PageComponent implements OnInit {
  isSmallScreen = false;

  columns: DocumentListPresetRef[] = [];

  constructor(
    store: Store<AppStore>,
    extensions: AppExtensionService,
    content: ContentManagementService,
    private uploadService: UploadService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    super(store, extensions, content);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions = this.subscriptions.concat([
      this.uploadService.fileUploadComplete.pipe(debounceTime(300)).subscribe(() => this.onFileUploadedEvent()),
      this.uploadService.fileUploadDeleted.pipe(debounceTime(300)).subscribe(() => this.onFileUploadedEvent()),

      this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
        this.isSmallScreen = result.matches;
      })
    ]);

    this.columns = this.extensions.documentListPresets.recent || [];
    const modifiedByColumn = {
      id: 'app.recent.modifiedBy',
      key: 'modifiedByUser.displayName',
      sortingKey: 'modifiedByUser',
      title: 'APP.DOCUMENT_LIST.COLUMNS.MODIFIED_BY',
      type: 'text',
      class: 'adf-ellipsis-cell adf-no-grow-cell',
      sortable: true,
      desktopOnly: true,
      order: 40
    } as DocumentListPresetRef;

    const statusColumn = {
      id: 'app.recent.status',
      key: 'properties.epmCore:status',
      title: 'APP.DOCUMENT_LIST.COLUMNS.EPM_STATUS',
      type: 'text',
      class: 'adf-ellipsis-cell narrow-cell statusColumn',
      sortable: true,
      desktopOnly: false,
      order: 70,
      sortingKey: 'properties.epmCore:status'
    } as DocumentListPresetRef;

    const revisionColumn = {
      id: 'app.recent.revision',
      key: 'properties.epmCore:revision',
      title: 'APP.DOCUMENT_LIST.COLUMNS.EPM_REVISION_SHORT',
      type: 'text',
      class: 'adf-ellipsis-cell narrow-cell',
      sortable: true,
      desktopOnly: false,
      order: 60,
      sortingKey: 'properties.pmCore:revision'
    } as DocumentListPresetRef;

    const versionColumn = {
      id: 'app.recent.version',
      key: 'properties.cm:versionLabel',
      title: 'APP.DOCUMENT_LIST.COLUMNS.VERSIONSHORT',
      type: 'text',
      class: 'adf-ellipsis-cell narrow-cell',
      sortable: true,
      desktopOnly: true,
      order: 50,
      sortingKey: 'properties.cm:versionLabel'
    } as DocumentListPresetRef;

    this.columns = this.columns.filter((item) => item.id !== 'app.recent.location' && item.id !== 'app.recent.size');
    this.columns.push(statusColumn, revisionColumn, modifiedByColumn, versionColumn);
    this.columns = this.columns.sort(sortByOrder);

    this.columns = this.columns.filter((column) => {
      // if (column.id === 'app.recent.modifiedBy') {
      //   return false;
      // }
      if (column.id === 'app.recent.version') {
        return false;
      }
      if (column.id === 'app.recent.revision') {
        return false;
      }
      if (column.id === 'app.recent.status') {
        return false;
      } else {
        return true;
      }
    });
  }

  onNodeDoubleClick(node: MinimalNodeEntity) {
    if (node && node.entry) {
      this.showPreview(node, { location: this.router.url });
    }
  }

  handleNodeClick(event: Event) {
    this.onNodeDoubleClick((event as CustomEvent).detail?.node);
  }

  handleSingleNodeClick(e: any) {
    this.onNodeDoubleClick(e.detail?.node);
  }

  private onFileUploadedEvent() {
    this.reload();
  }
}
