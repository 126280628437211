import { FormService, TextWidgetComponent } from '@alfresco/adf-core';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'epm-text-datalist',
  templateUrl: './text-datalist.component.html',
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class EpmTextDatalistWidgetComponent extends TextWidgetComponent {
  constructor(public formService: FormService) {
    super(formService);
  }
}
