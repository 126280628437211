import { WorkflowFormRenderer } from '../workflow-form-renderer';
import { Observable, of, Subject } from 'rxjs';
import { Item } from '../../../../api/models/item/item';
import { RestFormModel } from '../../../../api/models/rest-form-model/rest-form-model';
import { createFormFieldContainerJson } from '../../utils/form';
import { FormValues, TranslationService } from '@alfresco/adf-core';
import { DlWorkflowService } from './dl-workflow.service';
import { Injectable, OnDestroy } from '@angular/core';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { DynamicListsDataModel } from '../../../../api/models/epm/dynamiclists.model';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { RetrieveUxDataModel } from '../../../../api/models/epm/retrieve-ux-data.model';
import { TaskInstance } from '../../../../api/models/task/task-instance';
import { AttachmentMode } from '../../widgets/attach-file/attach-file-widget-component.component';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { LegacyContentService } from '../../../../services/legacy-content.service';
import { DatalistsDataModel } from '../../../../api/models/legacy/datalists-data.model';

export const DEPARTMENTS: { id: string; name: string }[] = [
  { id: 'architecture', name: 'Architecture' },
  { id: 'construction', name: 'Construction' },
  { id: 'electrical', name: 'Electrical' },
  { id: 'lowCurrent', name: 'LowCurrent' },
  { id: 'sanitary', name: 'Sanitary' },
  { id: 'road', name: 'road' },
  { id: 'railway', name: 'Railway' },
  { id: 'bridge', name: 'Bridge' },
  { id: 'hydraulic', name: 'Hydraulic' },
  { id: 'technological', name: 'Technological' },
  { id: 'landscape', name: 'Landscape' },
  { id: 'networks', name: 'Networks' }
];

/**
 * Standard workflow form Renderer.
 */
@Injectable({
  providedIn: 'root'
})
export class StandardWorkflowFormRenderer extends WorkflowFormRenderer implements OnDestroy {
  static key = 'stdWorkflow';
  id = 'stdWorkflow';
  name = 'Standard workflow';
  description = 'Default standard workflow form';

  constructor(
    translation: TranslationService,
    protected dlWorkflowService: DlWorkflowService,
    protected processDynamicFormData: ProcessDynamicFormData,
    protected legacyContentService: LegacyContentService,
    protected store: Store<AppStore>
  ) {
    super(translation);
  }

  protected onDestroy$ = new Subject<boolean>();

  createStartFormFields(_formFields: RestFormModel[]): Observable<object[]> {
    // After users selects a BPMN, show only a Workflow Type form field.
    // Only after users selects a Workflow Type, generate all other form fields.
    // Thanks to that logic, we can generate different field based on the Workflow Type (i.e. departments)
    return this.dlWorkflowService.fetchWfTypes(this.processDynamicFormData.wfDefModel.name).pipe(
      takeUntil(this.onDestroy$),
      map((model) => {
        const processTypes: DynamicListsDataModel[] = model.data;
        const options = processTypes ? processTypes.map((m) => ({ id: m.ref, name: m.name })) : [];
        return [this.jsonEpmWfDataListWfType(options)];
      })
    );
  }

  onProcessTypeSelect(processType: DynamicListsDataModel) {
    // after user selects a Workflow Type, add all other fields
    this.fetchUxData$(processType.ref).subscribe((uxData) => {
      // concatenate first field (process type) with all others
      const newFormFields = [
        this.processDynamicFormData.lastFormModelValue.json.fields[0],
        this.jsonEpmWfDataListWfNode(processType.ref, false, true),
        this.jsonEpmWfDataListWfSiteid(processType.siteid, false, true),
        this.jsonBpmWorkflowDescription(null, true, false),
        this.jsonBpmPriority(null, false, false),
        this.jsonEpmWfDataListWfDesc(null, false, true),
        ...this.jsonEpmWfDepartment([], false, uxData),
        this.jsonAssocPackageItemsAdded(),
        this.jsonBpmComment(null, false, false)
      ];

      const formValues: FormValues = {
        epmWf_dataListWfType: processType.name
      };
      // update form data
      this.processDynamicFormData.updateFormFieldsModel(newFormFields, formValues);
    });
  }

  createTaskFormFields(taskInstance: TaskInstance, variables: object, formFields: RestFormModel[], attachmentItems: Item[]): Observable<object[]> {
    const uxData$ = this.dlWorkflowService.retrieveUxData(variables['epmWf_dataListWfNode']);
    const formFieldsObj = {};
    formFields.forEach((field) => (formFieldsObj[field.name] = field));

    // render form fields based on the task form key
    switch (taskInstance.formResourceKey) {
      case 'stdWf:resubmit':
        return uxData$.pipe(
          map((uxData) => {
            return [
              ...this.renderTaskDefaultFormFields(formFieldsObj, variables, attachmentItems, uxData, false).concat([this.jsonNextTransition()])
            ];
          })
        );
      case 'stdWf:formalVerification':
        return this.renderStdWfFormalVerification(uxData$, variables, formFieldsObj, attachmentItems);
      default:
        return uxData$.pipe(
          map((uxData) => {
            return this.renderTaskDefaultFormFields(formFieldsObj, variables, attachmentItems, uxData, true).concat([
              this.jsonStdWfOutcome(
                variables['stdWf_outcome'],
                false,
                false,
                formFieldsObj['stdWf_outcome']?.allowedValues?.map((opt) => ({
                  id: opt,
                  name: `EPM.FORM.VAR.STDWF.OUTCOME.${opt.toUpperCase()}`
                }))
              )
            ]);
          })
        );
    }
  }

  protected renderStdWfFormalVerification(uxData$, variables: object, formFieldsObj: object, attachmentItems: Item[]) {
    return uxData$.pipe(
      switchMap((uxData: RetrieveUxDataModel) => {
        return this.legacyContentService
          .getDatalistData(variables['epmWf_dataListWfNode'], ['epmWf_taskIndex', 'epmWf_taskId', 'epmWf_dynamicTaskNameConfig'], {
            filterId: 'all',
            filterData: ''
          })
          .pipe(
            map((dataListsDataModel: DatalistsDataModel) => {
              const options: { id: string; name: string }[] = [
                { id: '', name: '' },
                { id: '-1', name: this.translation.instant('EPM.FORM.OPTION.END_WORKFLOW') },
                { id: '0', name: this.translation.instant('EPM.FORM.OPTION.REFILL_THE_APPLICATION') }
              ];
              dataListsDataModel?.items?.map((item) => {
                options.push({
                  id: item.itemData['prop_epmWf_taskIndex']?.value.toString(),
                  name: this.getTaskDynamicNameFromDataList(item)
                });
              });
              return this.renderTaskDefaultFormFields(formFieldsObj, variables, attachmentItems, uxData, true).concat([
                this.jsonEpmWfMoveToTaskIndex(0, false, false, options),
                this.jsonEpmWfMoveToOutcome(
                  variables['epmWf_moveToOutcome'],
                  false,
                  false,
                  formFieldsObj['epmWf_moveToOutcome'].allowedValues.map((a) => ({
                    id: a,
                    name: this.translation.instant(`EPM.FORM.OUTCOME.${a.toUpperCase()}`)
                  }))
                )
              ]);
            })
          );
      })
    );
  }

  protected getTaskDynamicNameFromDataList(item): string {
    return item.itemData['prop_epmWf_dynamicTaskNameConfig']
      ? item.itemData['prop_epmWf_dynamicTaskNameConfig'].displayValue
      : item.itemData['prop_epmWf_taskId']?.displayValue;
  }

  private renderTaskDefaultFormFields(
    _formFieldsObj: object,
    variables: object,
    attachmentItems: Item[],
    uxData: RetrieveUxDataModel,
    readOnly: boolean
  ): object[] {
    return [
      this.jsonEpmWfComments(variables['epmWf_comments'], false, true),
      this.jsonEpmWfDataListWfNode(variables['epmWf_dataListWfNode'], false, true),
      this.jsonEpmWfDataListWfType(variables['epmWf_dataListWfType']),
      this.jsonEpmWfDataListWfSiteid(variables['epmWf_dataListWfSiteid'], false, true),
      this.jsonBpmPriority(variables['bpm_workflowPriority'], false, readOnly),
      this.jsonBpmWorkflowDescription(variables['bpm_workflowDescription'], false, true),
      this.jsonEpmWfDataListWfDesc(variables['epmWf_dataListWfDesc'], false, readOnly),
      ...this.jsonEpmWfDepartment(variables['epmWf_department'], readOnly, uxData),
      this.jsonAssocPackageItemsAdded(
        variables['bpm_package'],
        attachmentItems,
        variables['epmWf_attachmentActionsReadOnly'] === 'NONE' ? AttachmentMode.NONE : AttachmentMode.ADD
      ),
      this.jsonBpmComment(variables['bpm_comment'], false, false)
    ];
  }

  protected fetchUxData$(processTypeRef: string) {
    return this.dlWorkflowService.retrieveUxData(processTypeRef).pipe(
      takeUntil(this.onDestroy$),
      catchError((err) => {
        this.store.dispatch(new SnackbarErrorAction('Data list workflow definition might not exist: ' + err));
        return of(
          new RetrieveUxDataModel({
            enableAdditionalSpecialist: false,
            taskDepartments: [],
            visibleMetadata: [],
            wfNameMetadata: '',
            wfNameRegex: ''
          })
        );
      })
    );
  }

  protected jsonEpmWfDepartment(value: string | any[], readOnly: boolean, uxData?: RetrieveUxDataModel) {
    const depFields = [];
    if (readOnly) {
      // Do not look at uxData, just render values.
      // Values might be as an array or comma separated string
      const valueArr: any[] = typeof value === 'string' ? value.split(',') : value ? value : [];
      return [
        createFormFieldContainerJson(
          'epmWf_department',
          this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.LABEL'),
          'multi-checkbox',
          valueArr,
          false,
          DEPARTMENTS.filter((dep) => valueArr.includes(dep.id)),
          readOnly
        )
      ];
    }
    if (uxData?.taskDepartments) {
      depFields.push(
        createFormFieldContainerJson(
          'epmWf_department',
          this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.LABEL'),
          'multi-checkbox',
          value,
          false,
          DEPARTMENTS.filter((dep) => uxData.taskDepartments.includes(dep.id)),
          readOnly
        )
      );
    } else {
      depFields.push(
        createFormFieldContainerJson(
          'epmWf_department',
          this.translation.instant('EPM.FORM.VAR.EPMWF.DEPARTMENT_OPTIONS.LABEL'),
          'multi-checkbox',
          uxData.taskDepartments,
          false,
          DEPARTMENTS.filter((dep) => uxData.taskDepartments.includes(dep.name)),
          readOnly
        )
      );
    }
    return depFields;
  }

  protected jsonEpmWfDataListWfNode(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson('epmWf_dataListWfNode', '', 'hidden-input', value, required, null, readOnly);
  }

  protected jsonEpmWfDataListWfTaskNode(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson('epmWf_dataListWfTaskNode', '', 'hidden-input', value, required, null, readOnly);
  }

  protected jsonEpmWfDataListWfType(options: { id: string; name: string }[]) {
    return createFormFieldContainerJson(
      'epmWf_dataListWfType',
      this.translation.instant('EPM.FORM.VAR.EPMWF.DATALISTWFTYPE'),
      'epmwf-dltype',
      '',
      true,
      options
    );
  }

  protected jsonEpmWfDataListWfTypeHidden(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson('epmWf_dataListWfType', '', 'hidden-input', value, required, null, readOnly);
  }

  protected jsonEpmWfDataListWfSiteid(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson('epmWf_dataListWfSiteid', '', 'hidden-input', value, required, null, readOnly);
  }

  protected jsonEpmWfDataListWfDesc(value: any, required: boolean, readOnly: boolean) {
    return createFormFieldContainerJson(
      'epmWf_dataListWfDesc',
      this.translation.instant('EPM.FORM.VAR.STDWF2.DATALISTWFDESC'),
      'multi-line-text',
      value,
      required,
      null,
      readOnly
    );
  }

  protected jsonEpmWfMoveToTaskIndex(value: any, required: boolean, readOnly: boolean, options: { id: string; name: string }[]) {
    return createFormFieldContainerJson(
      'epmWf_moveToTaskIndex',
      this.translation.instant('EPM.FORM.VAR.EPMWF.MOVETOTASKINDEX'),
      'hidden-input',
      value,
      required,
      options,
      readOnly
    );
  }

  protected jsonEpmWfMoveToOutcome(value: any, required: boolean, readOnly: boolean, options: { id: string; name: string }[]) {
    return createFormFieldContainerJson(
      'epmWf_moveToOutcome',
      this.translation.instant('EPM.FORM.VAR.EPMWF.MOVETOOUTCOME'),
      'outcome-buttons',
      value,
      required,
      options,
      readOnly
    );
  }

  protected jsonStdWfOutcome(value: any, required: boolean, readOnly: boolean, options: { id: string; name: string }[]) {
    return createFormFieldContainerJson(
      'stdWf_outcome',
      this.translation.instant('EPM.FORM.VAR.STDWF.OUTCOME.LABEL'),
      'outcome-buttons',
      value,
      required,
      options,
      readOnly
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
