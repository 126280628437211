<div class="epm-photo-upload-widget-container">
  <div>
    <div *ngIf="hasFile" fxLayout="column" fxLayoutAlign="center">
      <div *ngFor="let photoUrl of photoUrlsDynamic" class="epm-photo-item-wrapper">
        <img [src]="photoUrl" alt="Issue photo" width="100%">
        <button *ngIf="allowPreviousFileRemoval(photoUrl);" mat-icon-button [id]="'file-'+photoUrl+'-remove'" class="remove-button" color="warn" (click)="removeFile(photoUrl);" (keyup.enter)="removeFile(photoUrl);">
          <mat-icon class="mat-24">highlight_off</mat-icon>
        </button>
        <div>
          <button *ngIf="!isEditing && isEditable" mat-icon-button [id]="'file-'+photoUrl+'-edit'" class="edit-button" color="primary" (click)="editPhoto(photoUrl)" (keyup.enter)="editPhoto(photoUrl)">
            <mat-icon class="mat-24">edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(!hasFile || multipleOption) && !field.readOnly">
    <button mat-raised-button color="primary" (click)="uploadFiles.click()" type="button" [matTooltip]="field.tooltip"
            matTooltipPosition="above" matTooltipShowDelay="1000">
      {{ 'EPM.PHOTO_UPLOAD' | translate }}<mat-icon>file_upload</mat-icon>
    </button>
    <input #uploadFiles type="file" accept="image/*" capture="camera"
           [multiple]="multipleOption" [id]="field.form.nodeId"
           (change)="onFileChanged($event)" style="display: none;">
  </div>
  <div *ngIf="isEditing" class="button-container display-none">
    <button class="save-button" type="button" (click)="saveEdit()">{{ 'SAVE' | translate }}</button>
    <button class="close-button" type="button" (click)="cancelEdit()">{{ 'CLOSE' | translate }}</button>
    <div class="color-buttons display-none">
      <button class="color-button" type="button" *ngFor="let color of colors" [style.backgroundColor]="color" (click)="changeColor(color)"></button>
    </div>
  </div>
</div>
