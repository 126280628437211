import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldModel, FormFieldOption, FormService, WidgetComponent } from '@alfresco/adf-core';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';

@Component({
  selector: 'epm-dropdown-widget',
  templateUrl: './dropdown-widget.component.html',
  styleUrls: ['./dropdown-widget.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class DropdownWidgetComponent extends WidgetComponent implements OnInit {
  private readonly MOVE_TO_OUTCOME_BUTTON_ID = 'moveto';
  constructor(public formService: FormService, private processDynamicFormData: ProcessDynamicFormData) {
    super(formService);
  }

  ngOnInit(): void {
    this.field.options = this.field.options.filter((a) => a.id !== '');
    this.field.options.sort((a, b) => {
      return +a.id - +b.id;
    });
    this.field.options.unshift({
      id: '',
      name: ''
    });
    // if (!this.field.value) {
    //   this.processDynamicFormData.changeButtonState.next({ buttonId: this.MOVE_TO_OUTCOME_BUTTON_ID, state: 'disabled' });
    // }
  }

  getOptionValue(option: FormFieldOption, fieldValue: string): string {
    let optionValue: string = '';
    if (option.id === 'empty' || option.name !== fieldValue) {
      optionValue = option.id;
    } else {
      optionValue = option.name;
    }
    return optionValue;
  }

  isReadOnlyType(): boolean {
    return this.field.type === 'readonly';
  }

  showRequiredMessage(): boolean {
    return (this.isInvalidFieldRequired() || this.field.value === 'empty') && this.isTouched();
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 == c2;
  }

  onFieldChanged(item?: FormFieldModel) {
    super.onFieldChanged(item);

    const obj: { buttonId: string; state: 'enabled' | 'disabled' } = { buttonId: this.MOVE_TO_OUTCOME_BUTTON_ID, state: 'disabled' };
    if (item) {
      obj.state = 'enabled';
    }
    this.processDynamicFormData.changeButtonState.next(obj);
  }
}
