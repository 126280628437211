<aca-page-layout [hasError]="!isValidId">
  <aca-page-layout-header>
    <div class="epm-breadcrumb">
      <nav role="navigation" class="adf-breadcrumb-container">
        <div class="adf-breadcrumb-item">
          {{ 'EPM.PROCESS_DETAILS.LABEL' | translate}} {{ (processInstanceModel?.variables["bpm_workflowDescription"]) ? '- ' + processInstanceModel?.variables["bpm_workflowDescription"] : '' }}
        </div>
      </nav>
    </div>

    <adf-toolbar class="adf-toolbar--inline">
      <button mat-icon-button aria-label="close task details" (click)="goBack()"><mat-icon>close</mat-icon></button>
    </adf-toolbar>
  </aca-page-layout-header>

  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>

  <aca-page-layout-content>
    <div class="main-content">
      <mat-tab-group
        *ngIf="processInstanceModel"
        [selectedIndex]="0"
        class="epm-tabs" (selectedTabChange)="onSelectedTabChange($event)">
        <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.PHOTOS' | translate }}" *ngIf="hasIssueWfPhotos()">
          <div fxLayout="column" fxLayoutAlign="center" >
            <div *ngFor="let photoUrl of issueWfPhotoUrls">
              <mat-card class="example-card">
                <img mat-card-image [src]="photoUrl" alt="Issue photo" width="100%" style="max-width: 400px">
              </mat-card>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.ACTIVE_TASKS' | translate }}">
          <adf-datatable
            [rows]="activeTasksData"
            [stickyHeader]="true"
            (rowClick)="onTaskRowClick($any($event))"
          >
            <data-columns>
              <!-- Type Column -->
              <data-column type="text" key="type" title="{{ 'EPM.PROCESS_DETAILS.TASKS.ACTIVE.TYPE' | translate }}" [sortable]="false"></data-column>
              <!-- Assigned to Column -->
              <data-column type="text" key="assignedTo" title="{{ 'EPM.PROCESS_DETAILS.TASKS.ACTIVE.ASSIGNEDTO' | translate }}" [sortable]="false"></data-column>
              <!-- Due date Column -->
              <data-column type="date" key="dueDate" title="{{ 'EPM.PROCESS_DETAILS.TASKS.ACTIVE.DUEDATE' | translate }}" [sortable]="false"></data-column>
            </data-columns>
            <!-- TODO Actions -->

            <adf-no-content-template>
              <ng-template>
                <p>{{ 'EPM.PROCESS_DETAILS.TASKS.ACTIVE.EMPTY' | translate }}</p>
              </ng-template>
            </adf-no-content-template>
          </adf-datatable>
        </mat-tab>
        <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.PROPERTIES' | translate }}">
          <div>
            <adf-card-view
              data-automation-id="process-details-properties-card-view"
              [properties]="mainPropertiesItems"
              style="width: 100%;"
              [editable]="false">
            </adf-card-view>
          </div>
        </mat-tab>
        <!-- <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.ATTACHMENTS' | translate }}">
          <div *ngIf="attachmentNodes && attachmentNodes.length !== 0; else attachmentEmpty;">
            <epm-selected-file-view
              [selectedFiles]="attachmentNodes"
              [field]="dummyAttachmentField"
              [hasFile]="true"
              (fileClicked)="onAttachmentClicked($event)"
              (downloadFile)="onDownloadAttachment($event)"
            >
            </epm-selected-file-view>
          </div>
          <ng-template #attachmentEmpty>
            <adf-empty-content style="overflow: hidden;"
              icon="assignment"
              title="EPM.PROCESS_DETAILS.ATTACHMENTS.EMPTY">
            </adf-empty-content>
          </ng-template>
        </mat-tab> -->

        <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.HISTORIC_TASKS' | translate }}">
          <adf-datatable
            [rows]="historicTasksData"
            [stickyHeader]="true"
            (rowClick)="onTaskRowClick($any($event))"
          >
            <data-columns>
              <!-- Type Column -->
              <data-column type="text" key="type" title="{{ 'EPM.PROCESS_DETAILS.TASKS.HISTORIC.TYPE' | translate }}" [sortable]="false"></data-column>
              <!-- Date completed Column -->
              <data-column type="date" key="dateCompleted" title="{{ 'EPM.PROCESS_DETAILS.TASKS.HISTORIC.DATECOMPLETED' | translate }}" [sortable]="false"></data-column>
              <!-- Outcome Column -->
              <data-column type="text" key="outcome" title="{{ 'EPM.PROCESS_DETAILS.TASKS.HISTORIC.OUTCOME' | translate }}" [sortable]="false"></data-column>
              <!-- Comment Column -->
              <data-column type="text" key="comment" title="{{ 'EPM.PROCESS_DETAILS.TASKS.HISTORIC.COMMENT' | translate }}" [sortable]="false"></data-column>
            </data-columns>

            <adf-no-content-template>
              <ng-template>
                <p>{{ 'EPM.PROCESS_DETAILS.TASKS.HISTORIC.EMPTY' | translate }}</p>
              </ng-template>
            </adf-no-content-template>

          </adf-datatable>
        </mat-tab>
        <!-- <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.SIMPLE_DIAGRAM' | translate }}">
          <div class="mat-tab-body-content" style="transform: none;">
            <img width="auto"
                 [src]="simpleDiagramImg" alt="Process instance diagram">
          </div>
        </mat-tab> -->
        <mat-tab label="{{ 'EPM.PROCESS_DETAILS.TABS.PLAN' | translate }}" *ngIf="hasIssueWfPlan()">
          <div fxLayout="column" fxLayoutAlign="center" >
            <epm-plan-map [ngStyle]="{'height.px': 400, 'display': 'block'}" *ngIf="issueWfPlanUrl"
              [planUrl]="issueWfPlanUrl"
              [markersData]="[issueWfPlanMarker]">
            </epm-plan-map>
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
