import { ProcessListFilter } from '../../../model/process-list-filter';

/**
 * Process Instance query model for the REST API
 */
export class ProcessInstanceQuery {
  skipCount?: number;
  maxItems?: number;

  // does not work for processVariables
  // processInstanceProperties = ["startUserId", "startedAt", "bpm_workflowPriority", "bpm_workflowDueDate", "bpm_workflowDescription",
  //   "epmWf_dataListWfSiteid", "epmWf_dataListWfType", "epmWf_dataListWfNode", "epmWf_department"]
  properties?: string[];

  /**
   * Sorting param
   * Only one param is supported
   * Available sorting properties: processDefinitionId, durationInMillis, endedAt, businessKey, startedAt, id
   * Example: startedAt asc
   */
  orderBy?: string; // sorting param, i.e, "startedAt asc"
  where?: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}

const ORDER_BY_PROPERTIES = ['processDefinitionId', 'durationInMillis', 'endedAt', 'businessKey', 'startedAt', 'id'];
const ORDER_BY_DIRECTIONS = ['ASC', 'DESC'];

export function isValidOrderBy(orderBy: string) {
  let isValid = false;
  if (orderBy) {
    const orderByArr = orderBy.split(' ');
    if (
      orderByArr &&
      orderByArr.length === 2 &&
      ORDER_BY_PROPERTIES.includes(orderByArr[0]) &&
      ORDER_BY_DIRECTIONS.includes(orderByArr[1].toUpperCase())
    ) {
      isValid = true;
    }
  }
  return isValid;
}

/**
 * Create ProcessInstanceQuery for the REST API from the UI process list params
 */
export function createInstanceQuery(page: number, size: number, sort: string[], processListFilter: ProcessListFilter): ProcessInstanceQuery {
  const query = new ProcessInstanceQuery();
  query.skipCount = page * size;
  query.maxItems = size;
  if (isValidOrderBy(sort?.join(' '))) {
    query.orderBy = sort?.join(' ');
  }

  let where = '(';
  where += 'includeVariables=true';

  // apply processListFilter
  where += mapFiltersToWhereQuery(processListFilter);
  where += ')';

  query.where = where;
  return query;
}

function mapFiltersToWhereQuery(filters: ProcessListFilter): string {
  let query = '';
  if (filters) {
    if (filters.definitionId) {
      query += encodeAndQuery('processDefinitionKey', '=', filters.definitionId);
    }
    if (filters.startedAfter) {
      query += encodeAndQuery('startedAt', '>', filters.startedAfter.toISOString());
    }
    if (filters.startedBefore) {
      query += encodeAndQuery('startedAt', '<', filters.startedBefore.toISOString());
    }
    if (filters.startedBy) {
      query += encodeAndQuery('startUserId', '=', filters.startedBy);
    }
    if (filters.assignedTo) {
      // TODO (currently not available from api)
    }
    if (filters.status) {
      query += encodeAndQuery('status', '=', filters.status);
    } else {
      query += encodeAndQuery('status', '=', 'any');
    }
  }
  return query;
}

function encodeAndQuery(paramName: string, compareSign: '=' | '>' | '<', paramValue: string) {
  return ` AND ${paramName}${compareSign}'${paramValue}'`;
}
