import { Injectable } from '@angular/core';
import { AlfrescoApiService, LogService, TranslationService } from '@alfresco/adf-core';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TasksOrderBy, TaskStatus, UpdateTaskAction, WorkflowApi } from '../api/services/workflow-api.service';
import { ProcessInstanceList } from '../api/models/process/process-instance-list';
import { ProcessInstanceQuery } from '../api/models/process/process-instance-query';
import { Pagination, RestVariable, UploadApi } from '@alfresco/js-api';
import { RestVariableList } from '../api/models/rest/rest-variable-list';
import { ProcessInstanceEntry } from '../api/models/process/process-instance-entry';
import { ProcessInstance } from '../api/models/process/process-instance';
import { RestVariableEntry } from '../api/models/rest/rest-variable-entry';
import { TaskInstance } from '../api/models/task/task-instance';
import { ProcessInstanceTasksList } from '../api/models/process/process-instance-tasks-list';
import { ProcessInstanceTasksEntry } from '../api/models/process/process-instance-tasks-entry';
import { ProcessDefinition } from '../api/models/process/process-definition';
import { ProcessDefinitionsList } from '../api/models/process/process-definitions-list';
import { WorkflowDefinitionsModel } from '../api/models/legacy/workflow-definitions.model';
import { LegacyApiService } from '../api/services/legacy-api.service';
import { Store } from '@ngrx/store';
import { AppStore, SnackbarErrorAction, SnackbarInfoAction } from '@alfresco/aca-shared/store';
import { ProcessStartResponseEntry } from '../api/models/process/process-start-response';
import { TASKS_STATUS, TasksQueryRequestData } from '../api/models/task/tasks-query.model';
import { TaskInstanceList } from '../api/models/task/task-instance-list';
import { RestFormModel } from '../api/models/rest-form-model/rest-form-model';
import { throwIfNotDefined } from '../api/utils/assert';
import { ItemList } from '../api/models/item/item-list';
import { Item } from '../api/models/item/item';
import { Candidate } from '../api/models/candidate/candidate';
import { Node } from '@alfresco/js-api';
import { TaskInstanceEntry } from '../api/models/task/task-instance-entry';
import { CandidateList } from '../api/models/candidate/candidate-list';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  constructor(
    private snackBar: MatSnackBar,
    private workflowApi: WorkflowApi,
    private logService: LogService,
    private legacyApiService: LegacyApiService,
    private store: Store<AppStore>,
    private translation: TranslationService,
    private apiService: AlfrescoApiService
  ) {}

  private _uploadApi: UploadApi;

  get uploadApi(): UploadApi {
    this._uploadApi = this._uploadApi ?? new UploadApi(this.apiService.getInstance());
    return this._uploadApi;
  }

  /**
   * Gets Process Instances.
   * @param processInstanceQuery
   */
  getProcessInstances(processInstanceQuery: ProcessInstanceQuery): Observable<ProcessInstanceList> {
    return from(this.workflowApi.getProcesses(processInstanceQuery)).pipe(
      map((res: any) => res),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Gets Process Instance without variables.
   *
   * @param processInstanceId ID of the target process
   * @returns Metadata for the instance
   */
  getProcessInstance(processInstanceId: string): Observable<ProcessInstance> {
    return from(this.workflowApi.getProcessInstance(processInstanceId)).pipe(
      map((res: ProcessInstanceEntry) => res?.entry),
      catchError((err) => this.handleError(err))
    );
  }

  getProcessInstanceVariables(processInstanceId: string): Observable<RestVariable[]> {
    return from(this.workflowApi.getProcessInstanceVariables(processInstanceId)).pipe(
      map((res: RestVariableList) => res.list.entries),
      map((res: RestVariableEntry[]) => res.map((r) => r.entry)),
      catchError((err) => this.handleError(err))
    );
  }

  getProcessInstanceTasks(proessIntanceId: string, status: TaskStatus, orderBy?: TasksOrderBy): Observable<TaskInstance[]> {
    return from(this.workflowApi.getProcessTasks(proessIntanceId, status, orderBy)).pipe(
      map((res: ProcessInstanceTasksList) => res.list.entries.map((entry: ProcessInstanceTasksEntry) => entry.entry)),
      catchError((err) => this.handleError(err))
    );
  }

  getProcessDefinitionImage(processDefinitionsId: string): Observable<any> {
    return from(this.workflowApi.getProcessDefinitionImage(processDefinitionsId)).pipe(catchError((err) => this.handleError(err)));
  }

  getProcessDefinitionSimpleImage(processInstanceId: string): Observable<any> {
    return from(this.legacyApiService.getProcessDefinitionSimpleImage(processInstanceId)).pipe(catchError((err) => this.handleError(err)));
  }

  getProcessDefinitions(): Observable<ProcessDefinition[]> {
    return from(this.workflowApi.getProcessDefinitions()).pipe(
      map((processDefinitionsList: ProcessDefinitionsList) => {
        const defs: ProcessDefinition[] = [];
        if (processDefinitionsList && processDefinitionsList.list && processDefinitionsList.list.entries) {
          processDefinitionsList.list.entries.forEach((e) => {
            let exist = false;
            for (let def of defs) {
              if (def.key === e.entry.key) {
                exist = true;
                break;
              }
            }
            if (!exist) {
              defs.push(e.entry);
            }
          });
        }
        return defs;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  getLegacyProcessDefinitions(): Observable<WorkflowDefinitionsModel> {
    const exclude = [
      'activiti$activitiAdhoc',
      'activiti$activitiInvitationModerated',
      'activiti$activitiInvitationNominated',
      'activiti$activitiInvitationNominatedAddDirect',
      'activiti$activitiParallelGroupReview',
      'activiti$activitiParallelReview',
      'activiti$activitiReview',
      'activiti$activitiReviewPooled',
      'activiti$resetPassword'
    ];
    return from(this.legacyApiService.getProcessDefinitions(exclude)).pipe(
      map((processDefsModel) => {
        if (processDefsModel && processDefsModel.data) {
          // sort by title and name
          processDefsModel.data.sort((a, b) => {
            let aSortField = a.title ? a.title : a.name;
            let bSortField = b.title ? b.title : b.name;
            if (aSortField < bSortField) {
              return -1;
            }
            if (aSortField > bSortField) {
              return 1;
            }
            return 0;
          });
        }
        return processDefsModel;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  getProcessDefinitionStartModel(processDefKey: string): Observable<RestFormModel[]> {
    if (processDefKey && processDefKey.startsWith('activiti$')) {
      processDefKey = processDefKey.substring('activiti$'.length);
    }
    return from(this.workflowApi.getProcessDefinitionStartFormModel(processDefKey)).pipe(
      map((model) => {
        const formControls: RestFormModel[] = [];
        if (model && model.list) {
          for (let formControlEntry of model.list.entries) {
            formControls.push(formControlEntry.entry);
          }
        }
        return formControls;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  createProcess(processDefKey: string, variables: object = {}, items?: string[]): Observable<ProcessStartResponseEntry> {
    return from(this.workflowApi.createProcess(processDefKey, variables, items)).pipe(
      map((resp) => {
        if (resp && resp.entry) {
          return resp.entry;
        }
        return new ProcessStartResponseEntry();
      }),
      tap(
        (_) => {
          this.snackBar.open(this.translation.instant('EPM.PROCESS_SERVICE.CREATE_PROCESS_SUCCESS'), '', {
            duration: 200
          });
          this.logService.debug('Create process success');
        },
        (error) => {
          let msg = this.translation.instant('EPM.PROCESS_SERVICE.CREATE_PROCESS_ERROR');
          if (error) {
            try {
              msg += ': ' + JSON.parse(error['response'].text).error.briefSummary;
            } catch (e) {}
          }
          this.store.dispatch(new SnackbarErrorAction(msg));
        }
      ),
      catchError((err) => this.handleError(err))
    );
  }

  getTasks(tasksQuery?: TasksQueryRequestData): Observable<TaskInstanceList> {
    // if assignee is set, remove it from query because we want to get all user's tasks (assignee, candidateGroup and candidateUser)
    const newTasksQuery = new TasksQueryRequestData(tasksQuery);
    if (newTasksQuery.assignee) {
      newTasksQuery.assignee = undefined;
    }

    return from(this.workflowApi.getTasks(newTasksQuery)).pipe(
      switchMap((res: TaskInstanceList) => this.filterMyTaskAssignees(res, tasksQuery)),
      catchError((err) => this.handleError(err))
    );
  }

  private filterMyTaskAssignees(res: TaskInstanceList, tasksQuery?: TasksQueryRequestData): Observable<TaskInstanceList> {
    if (!tasksQuery?.assignee) {
      return of(res);
    }

    // handle assignee filter
    return forkJoin(res.list.entries.map((entry) => this.isMyTask(entry.entry, tasksQuery.assignee))).pipe(
      map((isMyTaskArr: boolean[]) => {
        return res.list.entries.filter((_, index) => isMyTaskArr[index] === true);
      }),
      map((newEntries: TaskInstanceEntry[]) => {
        // pagination totalItems is bad but we cannot compute it in client side
        const newPagination: Pagination = new Pagination({
          count: newEntries.length,
          hasMoreItems: res.list.pagination.hasMoreItems,
          totalItems: res.list.pagination.totalItems - res.list.pagination.count + newEntries.length,
          skipCount: res.list.pagination.skipCount,
          maxItems: res.list.pagination.maxItems
        });
        return new TaskInstanceList({
          list: {
            pagination: newPagination,
            entries: newEntries
          }
        });
      })
    );
  }

  private isMyTask(task: TaskInstance, userId: string): Observable<boolean> {
    if (task.state === TASKS_STATUS.COMPLETED || task.state === UpdateTaskAction.CLAIM) {
      return of(task.assignee === userId);
    }
    // Call /candidates API only when task is not completed yet
    return from(this.workflowApi.getTaskCandidates(task.id)).pipe(
      map((candidates: CandidateList) => {
        if (candidates?.list?.entries?.length > 0) {
          for (let candidate of candidates.list.entries) {
            if (candidate?.entry?.candidateId == userId) {
              return true;
            }
          }
        }
        return false;
      }),
      catchError((err) => {
        return this.handleError(err);
      })
    );
  }

  getTask(taskId: string): Observable<TaskInstance> {
    return from(this.workflowApi.getTask(taskId)).pipe(
      map((entry) => entry.entry),
      catchError((err) => this.handleError(err))
    );
  }

  getTaskVariables(taskId: string): Observable<{}> {
    return from(this.workflowApi.getTaskVariables(taskId)).pipe(
      map((res) => {
        const vars = {};
        res.list.entries.forEach((e) => {
          if (vars[e.entry.name] && e.entry.scope === 'global') {
            // global or local variable has been already added
            // always override global in benefit of local
          } else {
            vars[e.entry.name] = e.entry.value;
          }
        });
        return vars;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  completeTask(taskId: string, variables: RestVariable[]): Observable<TaskInstance> {
    throwIfNotDefined(variables, 'variables');
    return from(this.workflowApi.updateTask(taskId, UpdateTaskAction.COMPLETE, variables)).pipe(
      map((entry) => entry.entry),
      tap(
        (_) => {
          this.store.dispatch(new SnackbarInfoAction(this.translation.instant('EPM.PROCESS_SERVICE.COMPLETE_TASK_SUCCESS')));
          this.logService.debug('Complete task success');
        },
        (error) => {
          let msg = this.translation.instant('EPM.PROCESS_SERVICE.COMPLETE_TASK_ERROR');
          if (error) {
            try {
              msg += ': ' + JSON.parse(error['response'].text).error.briefSummary;
            } catch (e) {}
          }
          this.store.dispatch(new SnackbarErrorAction(msg));
        }
      ),
      catchError((err) => this.handleError(err))
    );
  }

  claimTask(taskId: string): Observable<TaskInstance> {
    return from(this.workflowApi.updateTask(taskId, UpdateTaskAction.CLAIM)).pipe(
      map((entry) => entry.entry),
      catchError((err) => {
        this.store.dispatch(new SnackbarErrorAction(this.translation.instant('EPM.PROCESS_SERVICE.CLAIM_TASK_ERROR')));
        return this.handleError(err);
      })
    );
  }

  unclaimTask(taskId: string): Observable<TaskInstance> {
    return from(this.workflowApi.updateTask(taskId, UpdateTaskAction.UNCLAIM)).pipe(
      map((entry) => entry.entry),
      catchError((err) => this.handleError(err))
    );
  }

  delegateTask(taskId: string, assignee: string): Observable<TaskInstance> {
    throwIfNotDefined(assignee, 'assignee');
    return from(this.workflowApi.updateTask(taskId, UpdateTaskAction.DELEGATE)).pipe(
      map((entry) => entry.entry),
      catchError((err) => this.handleError(err))
    );
  }

  resolveTask(taskId: string, variables: RestVariable[]): Observable<TaskInstance> {
    return from(this.workflowApi.updateTask(taskId, UpdateTaskAction.RESOLVE, variables)).pipe(
      map((entry) => entry.entry),
      catchError((err) => this.handleError(err))
    );
  }

  getTaskFormModel(taskId: string): Observable<RestFormModel[]> {
    return from(this.workflowApi.getTaskFormModel(taskId)).pipe(
      map((list) => {
        const fields: RestFormModel[] = [];
        list?.list?.entries?.map((entry) => fields.push(entry.entry));
        return fields;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  getTaskAttachments(taskId: string): Observable<Item[]> {
    return from(this.workflowApi.getTaskAttachments(taskId)).pipe(
      map((itemList: ItemList) => {
        if (itemList && itemList.list && itemList.list.entries) {
          return itemList.list.entries.map((e) => e.entry);
        }
        return [];
      }),
      catchError((err) => this.handleError(err))
    );
  }

  addTaskAttachments(taskId: string, attachmentIds: string[]): Observable<Item[]> {
    const attachments = attachmentIds ? attachmentIds.map((a) => ({ id: a })) : [];
    return from(this.workflowApi.addTaskAttachments(taskId, attachments)).pipe(
      map((itemList: ItemList) => {
        if (itemList && itemList.list && itemList.list.entries) {
          return itemList.list.entries.map((e) => e.entry);
        }
        return [];
      }),
      catchError((err) => this.handleError(err))
    );
  }

  removeTaskAttachments(taskId: string, attachmentId: string): Observable<any> {
    return from(this.workflowApi.removeTaskAttachment(taskId, attachmentId)).pipe(catchError((err) => this.handleError(err)));
  }

  getTaskCandidates(taskId: string): Observable<Candidate[]> {
    return from(this.workflowApi.getTaskCandidates(taskId)).pipe(
      map((candidateList) => {
        if (candidateList && candidateList.list && candidateList.list.entries) {
          return candidateList.list.entries.map((e) => e.entry);
        }
        return [];
      }),
      catchError((err) => this.handleError(err))
    );
  }

  uploadNewFile(file: File, parentFolderId, opts?: object): Observable<Node> {
    const mergedOpts = {
      override: true
    };
    if (opts) {
      Object.assign(mergedOpts, opts);
    }
    return from(this.uploadApi.uploadFile(file, '', parentFolderId, '', mergedOpts)).pipe(
      map((res: any) => ({
        ...res.entry,
        nodeId: res.entry.id
      })),
      catchError((err) => this.handleError(err))
    );
  }

  private handleError(error: any) {
    this.logService.error(error);
    return throwError(error || 'Server error');
  }
}
