import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormService, ObjectDataTableAdapter, TranslationService, WidgetComponent } from '@alfresco/adf-core';

interface EpmWfComment {
  completedBy: string;
  taskType: string;
  comment: string;
  completionDate: string;
  outcome: string;
}

@Component({
  selector: 'aca-epmwf-comments',
  templateUrl: './epmwf-comments.component.html',
  styleUrls: ['./epmwf-comments.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class EpmwfCommentsComponent extends WidgetComponent implements OnInit {
  data: ObjectDataTableAdapter;

  constructor(public formService: FormService, private translationService: TranslationService) {
    super(formService);
  }

  ngOnInit(): void {
    const epmWfComments: EpmWfComment[] = [];
    if (this.field.value && this.field.value.length > 0) {
      this.field.value.forEach((entry) => {
        let commentObj: EpmWfComment;
        if (entry) {
          const entryValues = entry.split(':');
          commentObj = {
            completedBy: entryValues[0],
            taskType: entryValues[1],
            comment: entryValues[2]?.replaceAll('`', ',')?.replaceAll('~', ':')?.replaceAll('\n', '<br>'),
            completionDate: entryValues[3].replaceAll('~', ':').split('. ')[1] || entryValues[3].replaceAll('~', ':'),
            outcome: entryValues[4]
          };
        }
        epmWfComments.push(commentObj);
      });
    }

    this.data = new ObjectDataTableAdapter(
      // data
      epmWfComments,
      // schema
      [
        {
          type: 'text',
          key: 'completedBy',
          title: this.translationService.instant('EPM.FORM.COMMENTS_TABLE.COMPLETED_BY'),
          sortable: false
        },
        {
          type: 'text',
          key: 'taskType',
          title: this.translationService.instant('EPM.FORM.COMMENTS_TABLE.TASK_TYPE'),
          cssClass: 'full-width',
          sortable: false
        },
        {
          type: 'text',
          key: 'comment',
          title: this.translationService.instant('EPM.FORM.COMMENTS_TABLE.COMMENT'),
          cssClass: 'full-width',
          sortable: false
        },
        {
          type: 'text',
          key: 'completionDate',
          title: this.translationService.instant('EPM.FORM.COMMENTS_TABLE.COMPLETION_DATE'),
          cssClass: 'full-width',
          sortable: false
        },
        {
          type: 'text',
          key: 'outcome',
          title: this.translationService.instant('EPM.FORM.COMMENTS_TABLE.OUTCOME'),
          cssClass: 'full-width',
          sortable: false
        }
      ]
    );
  }
}
