import { FormFieldModel, FormFieldOption, FormService, TextWidgetComponent } from '@alfresco/adf-core';
import { Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'epm-text-datalist',
  templateUrl: './text-datalist.component.html',
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class EpmTextDatalistWidgetComponent extends TextWidgetComponent {
  filteredOptions$: BehaviorSubject<FormFieldOption[]> = new BehaviorSubject<FormFieldOption[]>([]);
  constructor(public formService: FormService) {
    super(formService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.filteredOptions$.next(this.field.options);
  }

  onFieldChanged(field: FormFieldModel): void {
    super.onFieldChanged(field);
    const filteredOptions = this.field.options.filter((option) => option.name.toLowerCase().includes(this.field.value.toLowerCase()));
    this.filteredOptions$.next(filteredOptions);
  }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }
}
